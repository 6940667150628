import React from "react";
import Navigation from "../components/layout/Navigation";

const CookiePolicy = () => {
  return (
    <div className="page">
      <Navigation />
      <div className="container my-5 text-dark">
        <div className="row space_me">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h3 className="bold">Cookie Policy</h3>
            <hr className="my-4" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <p>
              A cookie is a file which helps analyse web traffic and keeps track
              of which websites you have visited. Cookies permit web
              applications to tailor operations to you as an individual by
              remembering information about your preferences. In order to help
              us analyse data about web page traffic we use traffic log cookies
              to identify which pages are being used ; this allows us to improve
              our website and tailor it to our customer’s needs. A cookie does
              not give us access to your computer or any information about you,
              only data you choose to share with us. Web browsers by default
              will automatically accept cookies, but you are able to turn them
              off in setting options; this may however prevent you from taking
              full advantage of the website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
