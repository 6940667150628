import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo_white.png";
import { east_african_flags } from "../../utils/helpers";

import instagram from "../../img/icons/colored/instagram.png";
import linkedin from "../../img/icons/colored/linkedin.png";
import facebook from "../../img/icons/colored/facebook.png";
import twitter from "../../img/icons/colored/twitter.png";
// import { Linkedin } from "react-feather";

import KE_Flag from "../../assets/flags/ke.png";
import TZ_Flag from "../../assets/flags/tz.png";
import UG_Flag from "../../assets/flags/ug.png";

const Footer = () => {
  const [country, setCountry] = useState(window.country.toLowerCase());

  const showDefault = (e) => {
    e.target.src = window.country.toLowerCase();
  };

  const displayFlag = () => {
    let flag;

    if (country === "ke") {
      flag = KE_Flag;
    }

    if (country === "tz") {
      flag = TZ_Flag;
    }

    if (country === "ug") {
      flag = UG_Flag;
    }

    return <img onError={(e) => showDefault(e)} src={flag} alt="flag" />;
  };

  const footer_links = [
    {
      title: "Our Company",
      links: [
        { name: "About Us", href: "about-us" },
        { name: "Contact Us", href: "contact-us" },
        { name: "Become a Partner", href: "become-a-partner" },
        { name: "Careers", href: "careers" },
        { name: "Partners", href: "partners" },
      ],
    },
    {
      title: "Insurance",
      links: [
        {
          name: "Individual Insurance",
          href: "explore-insurance/private-medical-insurance",
        },
        {
          name: "Group Insurance",
          href: "explore-insurance/group-medical-insurance",
        },
        { name: "Life Insurance", href: "explore-insurance/life-insurance" },
        {
          name: "Travel Insurance",
          href: "explore-insurance/travel-insurance",
        },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Contact", href: "contact-us" },
        { name: "Online Quote", href: "" },
        { name: "FAQs", href: "faqs" },
        { name: "Testimonials", href: "about-us" },
      ],
    },
    {
      title: "Support",
      links: [
        // { name: "Report a Problem", href: "" },
        // { name: "Write a Review", href: "" },
        { name: "Site Map", href: "sitemap" },
      ],
    },
  ];
  return (
    <>
      <footer className="footer text-white">
        <div className="container">
          <div className="row">
            {footer_links.map((f, i) => (
              <div className="col-md-2 py-md-5 pt-5 col-6" key={i}>
                <h5 className="text-subtext footer-header">{f.title}</h5>
                {f.links.map((f1, i1) => (
                  <div className="py-1" key={i1}>
                    <Link to={`/${f1.href}`} className="text-light footer-link">
                      {f1.name}
                    </Link>
                  </div>
                ))}
              </div>
            ))}

            <div className="col-md-2 py-md-5 pt-5 col-6">
              {/* <h5 className="text-subtext footer-header">
                <img src={logo} className="footer-logo" alt="" />
              </h5> */}
              <h5 className="text-subtext footer-header">
                <img src={logo} className="footer-logo" alt="" />
              </h5>
              <div className="py-1 mt-3">
                <Link to="/contact-us" className="text-light footer-link">
                  Talk to us
                </Link>
              </div>
              <div className="py-1">
                <a
                  href="mailto:info@policy-xpert.com"
                  className="text-light footer-link"
                >
                  info@policy-xpert.com
                </a>
              </div>
              <div className="country">
                <div className="flag-icon">{displayFlag()}</div>
                <select
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);

                    window.location.assign(
                      `/${e.target.value !== "ke" ? e.target.value : ""}`
                    );
                  }}
                >
                  {east_african_flags.map((f, i) => (
                    <option value={f.code} key={i}>
                      {f.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-2 py-md-5 pt-5 col-6">
              <h5 className="text-subtext footer-header opacity-0">
                <img src={logo} className="footer-logo" alt="" />
              </h5>

              <div className="py-1 mt-3">
                <Link to="/health-plans" className="text-light footer-link">
                  Stay Connected
                </Link>
              </div>
              <div className="py-1 py-1">
                <div className="d-flex flex-row align-items-center share-icons">
                  <div className="mr-3">
                    <a href="https://www.facebook.com/Policy-Xpert-Global-105558618957790">
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                  <div className="mr-3">
                    <a href="https://www.instagram.com/policyxpertglobal/">
                      <img src={instagram} alt="" />
                    </a>
                  </div>
                  <div className="mr-3">
                    <a href="https://www.linkedin.com/company/policy-xpert-global/">
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  {/* <div className="mr-3">
                    <a href="https://www.instagram.com/policyxpertofficial/">
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                  */}
                  {/* <div className="mr-3">
                    <img src={facebook} alt="" />
                  </div>
                  <div className="">
                    <img src={twitter} alt="" />
                  </div>
                  <div className="">
                    <img src={instagram} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex flex-row justify-content-between align-items-center flex-wrap text-subtext footer-sub">
            <div className="py-md-4 py-3">
              © Copyright 2020.policy-xpert.com. All Rights Reserved.
            </div>
            <div className="pb-3">
              <Link to="/privacy-policy" className="text-subtext px-3">
                Privacy Policy
              </Link>
              |
              <Link to="/terms-and-conditions" className="text-subtext px-3">
                Terms & Conditions
              </Link>
              |
              <Link to="/cookie-policy" className="text-subtext px-3">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
