import React, { useState } from "react";
import Navigation from "../components/layout/Navigation";
import { useForm } from "react-hook-form";
import axios from "axios";
import { decryptData, baseURL } from "../utils/helpers";
import Swal from "sweetalert2";

const WriteReview = () => {
  const { register, handleSubmit, reset } = useForm();

  const [loading, setloading] = useState(false);

  const onSubmit = async (data) => {
    let inputs = {};

    const user = JSON.parse(localStorage.user);

    inputs.random_id = user.random_id;
    inputs.name = data["name"];
    inputs.email_address = data["email"];
    inputs.phone = "0700 000 000";
    inputs.message = data["message"];

    setloading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: decryptData(localStorage.puid),
        },
      };

      const res = await axios.post(`${baseURL}/email/contact`, inputs, config);

      if (res.status === 200) {
        setloading(false);

        reset();

        Swal.fire({
          title: "Your Messege has been received successfully",
          icon: "success",
          // confirmButtonText: "Retry?",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          Swal.close();
        });
      }
    } catch (error) {}
  };

  return (
    <div className="contact">
      <Navigation />
      <div className="page-content">
        <section className="container my-5 d-flex flex-row justify-content-center text-center text-dark">
          <div className="sct-1 text">
            <h1 className="font-weight-bold">Write your review</h1>
            <div className="my-4">
              At policy-xpert we value client feedback, suggestions and
              complains. We promise to get back as soon as possible.
            </div>
            <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="form-control my-3"
                required={true}
                ref={register({
                  required: "Please add your name",
                })}
                disabled={loading ? true : false}
              />
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="form-control my-3"
                required={true}
                ref={register({
                  required: "Please provide email address",
                })}
                disabled={loading ? true : false}
              />
              <textarea
                name="message"
                className="form-control"
                placeholder="Type your message here..."
                rows="8"
                required={true}
                ref={register({
                  required: "Please add a message",
                })}
                disabled={loading ? true : false}
              ></textarea>
              <button
                type="submit"
                className="btn btn-lg btn-secondary mr-3 text-white px-4 mt-4"
                disabled={loading ? true : false}
              >
                Submit
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WriteReview;
