import aetna from "../img/aetna.webp";
import nhi from "../img/nhi.png";
import allianz from "../img/allianz.png";
import bupa from "../img/bupa.png";
import axa from "../img/axa.png";
import generali from "../img/generali.png";
import vumi from "../img/vumi.jpg";
import globhopper from "../img/globhopper.png";
import morgan_price from "../img/morgan_price.png";

import bg_aetna from "../img/logo_sm/aetna.png";
import bg_nhi from "../img/logo_sm/nhi.png";
import bg_allianz from "../img/logo_sm/allianz.png";
import bg_bupa from "../img/logo_sm/bupa.png";
import bg_axa from "../img/logo_sm/axa.png";
import bg_vumi from "../img/logo_sm/vumi.png";
import bg_globhopper from "../img/logo_sm/globhopper.png";
import bg_morgan_price from "../img/logo_sm/morgan_price.png";

import learn1 from "../img/learn/Learn-01small.jpg";
import learn2 from "../img/learn/Learn-02small.jpg";
import learn3 from "../img/learn/Learn-03small.jpg";

import plan1 from "../img/blogs/Kenya_Safety_Small.jpg";
import plan2 from "../img/blogs/Kenya_Healthcare_Small.jpg";
import plan3 from "../img/blogs/Kenya_Education_System_Small.jpg";
import plan4 from "../img/blogs/Kenya_Visa_Requirements_Small.jpg";
import plan5 from "../img/blogs/doctors.jpg";
import plan6 from "../img/blogs/international.jpg";
import plan7 from "../img/blogs/private.jpg";
import plan8 from "../img/blogs/stress.jpg";
import plan9 from "../img/trip/healthcare.jpg";
import plan10 from "../img/trip/settle.jpg";
import plan11 from "../img/trip/home.jpg";
import travel from "../img/blogs/Travel-Insurance.jpg";

// import article1 from "../img/front_banner/banner2.jpg";
import article2 from "../img/front_banner/banner3.jpg";
import article3 from "../img/front_banner/banner4.jpg";
import article4 from "../img/front_banner/banner5.jpg";
import article5 from "../img/front_banner/banner6.jpg";
import article6 from "../img/plan/expat-kenya.jpg";

import expat_guide_thumb from "../img/plan-your-trip/expat_guide_thumb.jpg";
import expat_guide_main from "../img/plan-your-trip/expat_guide_main.jpg";
import cost_of_living_thumb from "../img/plan-your-trip/cost_of_living_thumb.jpg";
import cost_of_living_main from "../img/plan-your-trip/cost_of_living_main.jpg";
import bussiness_opportunities_in_kenya_thumb from "../img/plan-your-trip/bussiness_opportunities_in_kenya_thumb.jpg";
import bussiness_opportunities_in_kenya_main from "../img/plan-your-trip/bussiness_opportunities_in_kenya_main.jpg";
import healthcare_in_kenya_thumb from "../img/plan-your-trip/healthcare_in_kenya_thumb.jpg";
import healthcare_in_kenya_main from "../img/plan-your-trip/healthcare_in_kenya_main.jpg";
import education_in_kenya_thumb from "../img/plan-your-trip/education_in_kenya_thumb.jpg";
import education_in_kenya_main from "../img/plan-your-trip/education_in_kenya_main.jpg";

import work_from_home_main from "../img/covid/work-from-home1.jpg";
import work_from_home_thumb from "../img/covid/work-from-home2.jpg";

// import article2_thumb from "../img/plan-your-trip/expat-kenya.jpg";
// import article2_main from "../img/plan-your-trip/expat-kenya.jpg";
// import article3_thumb from "../img/plan-your-trip/expat-kenya.jpg";
// import article3_main from "../img/plan-your-trip/expat-kenya.jpg";

import covid_article1 from "../img/covid/article1.jpg";
import covid_article2 from "../img/covid/article2.jpg";
import covid_article3 from "../img/covid/article3.jpg";

import diff_medical_int_local from "../img/policy-guide/diff_medical_int_local.jpg";
import consideration_when_choosing_plan from "../img/policy-guide/consideration_when_choosing_plan.jpg";
import finding_a_policy_that_works from "../img/policy-guide/finding_a_policy_that_works.jpg";
import suprising_cost_effectivness from "../img/policy-guide/suprising_cost_effectivness.jpg";
import why_you_should_policy_expert from "../img/policy-guide/why_you_should_policy_expert.jpg";

export const actual_blogs = [
  {
    id: 118909,
    banner: expat_guide_thumb,
    category: "Lifestyle",
    date: "January 10th 2022",
    published_on: "29th March 2022",
    title: "Expat guide to living in Kenya",
    intro: "",
    content:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
  },
];

export const covid_articles = [
  {
    id: 112345,
    img: covid_article1,
    img_main: covid_article1,
    published_on: "29th March 2022",
    title: "Travelling to Kenya Covid-19",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
    category: "Lifestyle",
  },
  {
    id: 112567,
    img: covid_article2,
    img_main: covid_article2,
    published_on: "29th March 2022",
    title: "Vaccinations",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
    category: "Lifestyle",
  },
  {
    id: 112991,
    img: covid_article3,
    img_main: covid_article3,
    published_on: "29th March 2022",
    title: "The pandemic two years later",
    subtitle: "5 ways life has changed since Covid-19",
    author: "Bharat Tavares",
    paragraph:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
    category: "Lifestyle",
  },
  {
    id: 113111,
    img: work_from_home_main,
    img_main: work_from_home_thumb,
    published_on: "29th March 2022",
    title: "Remote working in Nairobi",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "As we return to some sense of normalcy, a few things may be part of a new normal that might be considered an impossibility in a developing economy ...",
    category: "Lifestyle",
  },
];

export const blog_articles = [
  {
    id: 118459,
    img: expat_guide_thumb,
    img_main: expat_guide_main,
    published_on: "29th March 2022",
    title: "Expat guide to living in Kenya",
    author: "Bharat Tavares",
    paragraph:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
    category: "Lifestyle",
  },
  {
    id: 118909,
    img: cost_of_living_thumb,
    img_main: cost_of_living_main,
    published_on: "29th March 2022",
    title: "The cost of living in Kenya",
    author: "Bharat Tavares",
    paragraph:
      "Nairobi is gaining a reputation as a place where locals, artists and expats are transforming the city into a more vibrant",
    category: "Lifestyle",
  },
  {
    id: 1126459,
    img: bussiness_opportunities_in_kenya_thumb,
    img_main: bussiness_opportunities_in_kenya_main,
    published_on: "29th March 2022",
    title: "Business opportunities in Kenya",
    author: "Bharat Tavares",
    paragraph:
      "Kenya is becoming a key player in the IT industry. Infrastructure projects offer tons of opportunities for project",
    category: "Service",
  },
  {
    id: 1121229,
    img: healthcare_in_kenya_thumb,
    img_main: healthcare_in_kenya_main,
    published_on: "29th March 2022",
    title: "Health and Healthcare in Kenya",
    author: "Bharat Tavares",
    paragraph:
      "Even if you usually are very fit, it is a good idea to discuss your plans with your doctor and ensure that all of your vaccinations are up to date",
    category: "Wellbeing",
  },
  {
    id: 1123449,
    img: education_in_kenya_thumb,
    img_main: education_in_kenya_main,
    published_on: "29th March 2022",
    title: "Education in Kenya",
    author: "Bharat Tavares",
    paragraph:
      "If you are moving to Kenya with your family and children, international schools can be a fantastic way to ensure",
    category: "Lifestyle",
  },
];

export const sideblogs = [
  // {
  //   id: 1112223,
  //   banner: article1,
  //   category: "Wellbeing",
  //   title: "Health insurance if you are unemployed",
  //   content:
  //     "<h4>Supply Chain Manager @<strong><i>CargoChainzz Pvt Limited </i></strong></h4><br/><p>This job requires at least 3 years of experience</p>",
  // },
  {
    id: 1112223,
    banner: article6,
    category: "Lifestyle",
    date: "January 10th 2022",
    published_on: "29th March 2022",
    title: "Expat guide to living in Kenya",
    intro:
      "When you think of Africa, you're probably thinking of Kenya. It is the home of some of the most recognised animals in the world, not to mention incredible terrain and people. Kenya is a land of endless variety, with mile after mile of the famous Rift Valley standing as a testament to Africa's...",
    content:
      "<p>When you think of Africa, you're probably thinking of Kenya." +
      " It is the home of some of the most recognised animals in the world," +
      " not to mention incredible terrain and people. Kenya is a land of endless" +
      " variety, with mile after mile of the famous Rift Valley standing as a testament" +
      " to Africa's geological and human history.</p>",
  },
  {
    id: 2223344,
    banner: article2,
    category: "Wellbeing",
    published_on: "29th March 2022",
    title: "Need to Knows about living in Kenya",
    content:
      "Health insurers leverage their market power to obtain price concessions from clinicians or hospitals and health care systems or, alternatively, to screen out high-cost providers from their networks. Covered patients benefit from these discounts even when paying out of pocket for services (with the exception of prescription drugs, for patients often pay list prices even when they have insurance). Policies that focus on this function of health insurance affect the negotiating ",
  },
  {
    id: 9022928,
    banner: article3,
    category: "Wellbeing",
    published_on: "29th March 2022",
    title: "What Are Your Options for Individual Health Insurance?",
    content:
      "Health insurers leverage their market power to obtain price concessions from clinicians or hospitals and health care systems or, alternatively, to screen out high-cost providers from their networks. Covered patients benefit from these discounts even when paying out of pocket for services (with the exception of prescription drugs, for patients often pay list prices even when they have insurance). Policies that focus on this function of health insurance affect the negotiating ",
  },
  {
    id: 7652341,
    banner: article4,
    category: "Wellbeing",
    published_on: "29th March 2022",
    title: "How to Get Health Insurance During Open Enrollment",
    content:
      "Health insurers leverage their market power to obtain price concessions from clinicians or hospitals and health care systems or, alternatively, to screen out high-cost providers from their networks. Covered patients benefit from these discounts even when paying out of pocket for services (with the exception of prescription drugs, for patients often pay list prices even when they have insurance). Policies that focus on this function of health insurance affect the negotiating ",
  },
  {
    id: 8762349,
    banner: article5,
    category: "Wellbeing",
    published_on: "29th March 2022",
    title: "Choosing a High or Low-Deductible Health Insurance Plan?",
    content:
      "Health insurers leverage their market power to obtain price concessions from clinicians or hospitals and health care systems or, alternatively, to screen out high-cost providers from their networks. Covered patients benefit from these discounts even when paying out of pocket for services (with the exception of prescription drugs, for patients often pay list prices even when they have insurance). Policies that focus on this function of health insurance affect the negotiating ",
  },
];

export const policy_guide = [
  // import diff_medical_int_local from "../img/policy-guide/diff_medical_int_local.jpg";
  // import consideration_when_choosing_plan from "../img/policy-guide/consideration_when_choosing_plan.jpg";
  // import finding_a_policy_that_works from "../img/policy-guide/finding_a_policy_that_works.jpg";
  // import suprising_cost_effectivness from "../img/policy-guide/suprising_cost_effectivness.jpg";
  // import why_you_should_policy_expert from "../img/policy-guide/why_you_should_policy_expert.jpg";
  {
    id: 3041004,
    img: why_you_should_policy_expert,
    img_main: why_you_should_policy_expert,
    banner: why_you_should_policy_expert,
    published_on: "29th March 2022",
    title: "Looking for an Expert?",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "When you're looking for a medical insurance provider, you have a list of questions that need answering...",
    category: "Healthcare",
  },
  {
    id: 3001000,
    img: diff_medical_int_local,
    img_main: diff_medical_int_local,
    banner: diff_medical_int_local,
    published_on: "29th March 2022",
    title: "Differences between Local and International policies",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "When it comes to health insurance, there are two main options: local and international policies. Both have their benefits but the protection afforded by global coverage makes it the obvious choice for many professionals...",
    category: "Healthcare",
  },
  {
    id: 3011001,
    img: consideration_when_choosing_plan,
    img_main: consideration_when_choosing_plan,
    banner: consideration_when_choosing_plan,
    published_on: "29th March 2022",
    title: "Consideration when choosing a health plan for your family",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "Ever thought about buying insurance for your family? You need to go through the details of your family, the area where you live and other things before choosing. The fact is, the majority...",
    category: "Healthcare",
  },
  {
    id: 3021002,
    img: finding_a_policy_that_works,
    img_main: finding_a_policy_that_works,
    banner: finding_a_policy_that_works,
    published_on: "29th March 2022",
    title: "Finding a policy that works for you",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "Finding the right health plan for your family can be challenging and time-consuming. Fortunately, Policy Xpert makes it easy for you to research, compare, and purchase health insurance from some of the world's top international...",
    category: "Healthcare",
  },
  {
    id: 3031003,
    img: suprising_cost_effectivness,
    img_main: suprising_cost_effectivness,
    banner: suprising_cost_effectivness,
    published_on: "29th March 2022",
    title: "The cost-effectiveness of International Health Policies",
    subtitle: "",
    author: "Bharat Tavares",
    paragraph:
      "Consider several things if you're considering international health insurance for you and your family. One of the main questions people have is whether or not the premiums are worth...",
    category: "Healthcare",
  },
];

export const coverage_areas = [
  { id: "1", name: "Africa, Indian Sub Continent" },
  { id: "2", name: "Worldwide exc. USA" },
  { id: "3", name: "Worldwide" },
];

export const dependant_relations = [
  { id: "2", name: "Spouse" },
  { id: "3", name: "Son" },
  { id: "4", name: "Daughter" },
];

export const slider_ins_companies = [
  {
    img: allianz,
    big_img: bg_allianz,
    name: "",
    biography: "",
  },
  {
    img: axa,
    big_img: bg_axa,
    name: "",
    biography: "",
  },
  {
    img: bupa,
    big_img: bg_bupa,
    name: "",
    biography: "",
  },
  {
    img: globhopper,
    big_img: bg_globhopper,
    name: "",
    biography: "",
  },
  {
    img: nhi,
    big_img: bg_nhi,
    name: "",
    biography: "",
  },

  {
    img: vumi,
    big_img: bg_vumi,
    name: "",
    biography: "",
  },

  // {
  //   img: globhopper,
  //   big_img: bg_globhopper,
  //   name: "",
  //   biography: "",
  // },

  // {
  //   img: morgan_price,
  //   big_img: bg_morgan_price,
  //   name: "",
  //   biography: "",
  // },
];

export const ins_companies = [
  // {
  //   img: aetna,
  //   big_img: bg_aetna,
  //   name: "",
  //   biography: "",
  // },
  {
    img: nhi,
    big_img: bg_nhi,
    name: "",
    biography: "",
  },
  {
    img: allianz,
    big_img: bg_allianz,
    name: "",
    biography: "",
  },
  {
    img: bupa,
    big_img: bg_bupa,
    name: "",
    biography: "",
  },
  {
    img: axa,
    big_img: bg_axa,
    name: "",
    biography: "",
  },
  {
    img: vumi,
    big_img: bg_vumi,
    name: "",
    biography: "",
  },
  // {
  //   img: generali,
  //   name: "",
  //   biography: "",
  // },
];

export const default_countries = ["KE", "TZ", "UG", "ZM"];

export const countryList = [
  { nationality: "Afghan", code: "AF", name: "Afghanistan" },
  { nationality: "Albanian", code: "AL", name: "Albania" },
  { nationality: "Algerian", code: "DZ", name: "Algeria" },
  { nationality: "Samoan", code: "AS", name: "American Samoa" },
  { nationality: "Andorran", code: "AD", name: "Andorra" },
  { nationality: "Angolan", code: "AO", name: "Angola" },
  { nationality: "Anguillan", code: "AI", name: "Anguilla" },
  { nationality: "Antarctican", code: "AQ", name: "Antarctica" },
  { nationality: "Antiguan", code: "AG", name: "Antigua and Barbuda" },
  { nationality: "Argentine", code: "AR", name: "Argentina" },
  { nationality: "Armenian", code: "AM", name: "Armenia" },
  { nationality: "Arubian", code: "AW", name: "Aruba" },
  { nationality: "Australian", code: "AU", name: "Australia" },
  { nationality: "Austrian", code: "AT", name: "Austria" },
  { nationality: "Azerbaijani", code: "AZ", name: "Azerbaijan" },
  { nationality: "Bahamian", code: "BS", name: "Bahamas" },
  { nationality: "Bahraini", code: "BH", name: "Bahrain" },
  { nationality: "Bengali", code: "BD", name: "Bangladesh" },
  { nationality: "Barbadian", code: "BB", name: "Barbados" },
  { nationality: "Belarusian", code: "BY", name: "Belarus" },
  { nationality: "Belgian", code: "BE", name: "Belgium" },
  { nationality: "Belizean", code: "BZ", name: "Belize" },
  { nationality: "Beninois", code: "BJ", name: "Benin" },
  { nationality: "Bermudian", code: "BM", name: "Bermuda" },
  { nationality: "Bhutanese", code: "BT", name: "Bhutan" },
  { nationality: "Bolivian", code: "BO", name: "Bolivia" },
  { nationality: "Bosnian", code: "BA", name: "Bosnia and Herzegovina" },
  { nationality: "Botswanan", code: "BW", name: "Botswana" },
  { nationality: "Brazilian", code: "BR", name: "Brazil" },
  { nationality: "Bruneian", code: "BN", name: "Brunei" },
  { nationality: "Bulgarian", code: "BG", name: "Bulgaria" },
  { nationality: "Burkinabé", code: "BF", name: "Burkina Faso" },
  { nationality: "Burundian", code: "BI", name: "Burundi" },
  { nationality: "Cambodian", code: "KH", name: "Cambodia" },
  { nationality: "Cameroonian", code: "CM", name: "Cameroon" },
  { nationality: "Canadian", code: "CA", name: "Canada" },
  { nationality: "Cape Verdian", code: "CV", name: "Cape Verde" },
  { nationality: "Caymanians", code: "KY", name: "Cayman Islands" },
  {
    nationality: "Central African",
    code: "CF",
    name: "Central African Republic",
  },
  { nationality: "Chadian", code: "TD", name: "Chad" },
  { nationality: "Chilean", code: "CL", name: "Chile" },
  { nationality: "Chinese", code: "CN", name: "China" },
  { nationality: "I-Kiribati", code: "CX", name: "Christmas Island" },
  { nationality: "Colombian", code: "CO", name: "Colombia" },
  { nationality: "Comorian", code: "KM", name: "Comoros" },
  { nationality: "Congolese", code: "CD", name: "Congo" },
  { nationality: "Cook Islanders", code: "CK", name: "Cook Islands" },
  { nationality: "Costa Rican", code: "CR", name: "Costa Rica" },
  { nationality: "Ivorian", code: "CI", name: "Cote D'Ivoire" },
  { nationality: "Croatian", code: "HR", name: "Croatia" },
  { nationality: "Cuban", code: "CU", name: "Cuba" },
  { nationality: "Cypriot", code: "CY", name: "Cyprus" },
  { nationality: "Czech", code: "CZ", name: "Czech Republic" },
  { nationality: "Danish", code: "DK", name: "Denmark" },
  { nationality: "Djiboutian", code: "DJ", name: "Djibouti" },
  { nationality: "Dominican", code: "DM", name: "Dominica" },
  { nationality: "Dominican", code: "DO", name: "Dominican Republic" },
  { nationality: "Ecuadorian", code: "EC", name: "Ecuador" },
  { nationality: "Egyptian", code: "EG", name: "Egypt" },
  { nationality: "Salvadoran", code: "SV", name: "El Salvador" },
  { nationality: "Equatoguinean", code: "GQ", name: "Equatorial Guinea" },
  { nationality: "Eritrean", code: "ER", name: "Eritrea" },
  { nationality: "Estonian", code: "EE", name: "Estonia" },
  { nationality: "Ethiopian", code: "ET", name: "Ethiopia" },
  { nationality: "Faroese", code: "FO", name: "Faroe Islands" },
  { nationality: "Fijian", code: "FJ", name: "Fiji" },
  { nationality: "Finnish", code: "FI", name: "Finland" },
  { nationality: "French", code: "FR", name: "France" },
  { nationality: "The Tahitians", code: "PF", name: "French Polynesia" },
  { nationality: "Gabonese", code: "GA", name: "Gabon" },
  { nationality: "Gambian", code: "GM", name: "Gambia" },
  { nationality: "Georgian", code: "GE", name: "Georgia" },
  { nationality: "German", code: "DE", name: "Germany" },
  { nationality: "Ghanaian", code: "GH", name: "Ghana" },
  { nationality: "Gibraltar", code: "GI", name: "Gibraltar" },
  { nationality: "Greek", code: "GR", name: "Greece" },
  { nationality: "Greenlandic", code: "GL", name: "Greenland" },
  { nationality: "Grenadian", code: "GD", name: "Grenada" },
  { nationality: "Guamanian", code: "GU", name: "Guam" },
  { nationality: "Guatemalan", code: "GT", name: "Guatemala" },
  { nationality: "Guernsey", code: "GG", name: "Guernsey" },
  { nationality: "Guinean", code: "GN", name: "Guinea" },
  { nationality: "Bissau-Guinean", code: "GW", name: "Guinea-Bissau" },
  { nationality: "Guyanese", code: "GY", name: "Guyana" },
  { nationality: "Haitian", code: "HT", name: "Haiti" },
  { nationality: "Honduran", code: "HN", name: "Honduras" },
  { nationality: "Hongkongers", code: "HK", name: "Hong Kong" },
  { nationality: "Hungarian", code: "HU", name: "Hungary" },
  { nationality: "Icelandic", code: "IS", name: "Iceland" },
  { nationality: "Indian", code: "IN", name: "India" },
  { nationality: "Indonesian", code: "ID", name: "Indonesia" },
  { nationality: "Iranian", code: "IQ", name: "Iraq" },
  { nationality: "Irish", code: "IE", name: "Ireland" },
  { nationality: "Iraqi", code: "IM", name: "Isle of Man" },
  { nationality: "Israeli", code: "IL", name: "Israel" },
  { nationality: "Italian", code: "IT", name: "Italy" },
  { nationality: "Jamaican", code: "JM", name: "Jamaica" },
  { nationality: "Japanese", code: "JP", name: "Japan" },
  { nationality: "Jersey", code: "JE", name: "Jersey" },
  { nationality: "Jordanian", code: "JO", name: "Jordan" },
  { nationality: "Kazakhstani", code: "KZ", name: "Kazakhstan" },
  { nationality: "Kenyan", code: "KE", name: "Kenya" },
  { nationality: "I-Kiribati", code: "KI", name: "Kiribati" },
  { nationality: "North Korean", code: "KW", name: "Kuwait" },
  { nationality: "Kyrgyzstani", code: "KG", name: "Kyrgyzstan" },
  { nationality: "Latvian", code: "LV", name: "Latvia" },
  { nationality: "Lebanese", code: "LB", name: "Lebanon" },
  { nationality: "Basotho", code: "LS", name: "Lesotho" },
  { nationality: "Liberian", code: "LR", name: "Liberia" },
  { nationality: "Libyan", code: "LY", name: "Libya" },
  { nationality: "Liechtensteiner", code: "LI", name: "Liechtenstein" },
  { nationality: "Lithuanian", code: "LT", name: "Lithuania" },
  { nationality: "Luxembourgish", code: "LU", name: "Luxembourg" },
  { nationality: "Macanese", code: "MO", name: "Macau" },
  { nationality: "Macedonian", code: "MK", name: "Macedonia" },
  { nationality: "Malagasy", code: "MG", name: "Madagascar" },
  { nationality: "Malawian", code: "MW", name: "Malawi" },
  { nationality: "Malaysian", code: "MY", name: "Malaysia" },
  { nationality: "Maldivian", code: "MV", name: "Maldives" },
  { nationality: "Malian", code: "ML", name: "Mali" },
  { nationality: "Maltese", code: "MT", name: "Malta" },
  { nationality: "Marshallese", code: "MH", name: "Marshall Islands" },
  { nationality: "Mauritanian", code: "MR", name: "Mauritania" },
  { nationality: "Mauritian", code: "MU", name: "Mauritius" },
  { nationality: "Mayotte", code: "YT", name: "Mayotte" },
  { nationality: "Mexican", code: "MX", name: "Mexico" },
  { nationality: "Monégasques", code: "MC", name: "Monaco" },
  { nationality: "Mongolian", code: "MN", name: "Mongolia" },
  { nationality: "Montenegrin", code: "ME", name: "Montenegro" },
  { nationality: "Montserrat", code: "MS", name: "Montserrat" },
  { nationality: "Moroccan", code: "MA", name: "Morocco" },
  { nationality: "Mozambican", code: "MZ", name: "Mozambique" },
  { nationality: "Burmese", code: "MM", name: "Myanmar" },
  { nationality: "Namibian", code: "NA", name: "Namibia" },
  { nationality: "Nauruan", code: "NR", name: "Nauru" },
  { nationality: "Nepalese", code: "NP", name: "Nepal" },
  { nationality: "Netherlandic", code: "NL", name: "Netherlands" },
  { nationality: "Kanak", code: "NC", name: "New Caledonia" },
  { nationality: "Zelanian", code: "NZ", name: "New Zealand" },
  { nationality: "Nicaraguan", code: "NI", name: "Nicaragua" },
  { nationality: "Nigerien", code: "NE", name: "Niger" },
  { nationality: "Nigerian", code: "NG", name: "Nigeria" },
  { nationality: "Niueans", code: "NU", name: "Niue" },
  {
    nationality: "Northern Marianan",
    code: "MP",
    name: "Northern Mariana Islands",
  },
  { nationality: "Norwegian", code: "NO", name: "Norway" },
  { nationality: "Omani", code: "OM", name: "Oman" },
  { nationality: "Pakistani", code: "PK", name: "Pakistan" },
  { nationality: "Palauan", code: "PW", name: "Palau" },
  { nationality: "Palestinian", code: "PS", name: "Palestine" },
  { nationality: "Panamanian", code: "PA", name: "Panama" },
  { nationality: "Papuan", code: "PG", name: "Papua New Guinea" },
  { nationality: "Paraguayan", code: "PY", name: "Paraguay" },
  { nationality: "Peruvian", code: "PE", name: "Peru" },
  { nationality: "Filipino", code: "PH", name: "Philippines" },
  { nationality: "Polish", code: "PL", name: "Poland" },
  { nationality: "Portuguese", code: "PT", name: "Portugal" },
  { nationality: "Puerto Rican", code: "PR", name: "Puerto Rico" },
  { nationality: "Qatari", code: "QA", name: "Qatar" },
  { nationality: "Réunionese", code: "RE", name: "Reunion" },
  { nationality: "Romanian", code: "RO", name: "Romania" },
  { nationality: "Russian", code: "RU", name: "Russia" },
  { nationality: "Rwandese", code: "RW", name: "Rwanda" },
  { nationality: "Saint Barthélem", code: "BL", name: "Saint Barthelemy" },
  { nationality: "Saint Helenians", code: "SH", name: "Saint Helena" },
  { nationality: "Kittitian", code: "KN", name: "Saint Kitts and Nevis" },
  { nationality: "Lucian", code: "LC", name: "Saint Lucia" },
  { nationality: "Saint Martin", code: "MF", name: "Saint Martin" },
  {
    nationality: "Saint Pierre and Miquelon",
    code: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    nationality: "Saint Vincentian",
    code: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  { nationality: "Samoan", code: "WS", name: "Samoa" },
  { nationality: "Sammarinese", code: "SM", name: "San Marino" },
  { nationality: "São Toméan", code: "ST", name: "Sao Tome and Principe" },
  { nationality: "Saudi Arabian", code: "SA", name: "Saudi Arabia" },
  { nationality: "Senegalese", code: "SN", name: "Senegal" },
  { nationality: "Serbian", code: "RS", name: "Serbia" },
  { nationality: "Seychellois", code: "SC", name: "Seychelles" },
  { nationality: "Sierra Leonean", code: "SL", name: "Sierra Leone" },
  { nationality: "Singaporean", code: "SG", name: "Singapore" },
  { nationality: "Slovak", code: "SK", name: "Slovakia" },
  { nationality: "Slovenian", code: "SI", name: "Slovenia" },
  { nationality: "Solomon Island", code: "SB", name: "Solomon Islands" },
  { nationality: "Somali", code: "SO", name: "Somalia" },
  { nationality: "South African", code: "ZA", name: "South Africa" },
  { nationality: "South Sudanese", code: "SS", name: "South Sudan" },
  { nationality: "Spanish", code: "ES", name: "Spain" },
  { nationality: "Sri Lankan", code: "LK", name: "Sri Lanka" },
  { nationality: "Sudanese", code: "SD", name: "Sudan" },
  { nationality: "Surinamese", code: "SR", name: "Suriname" },
  {
    nationality: "Svalbard and Jan Mayen",
    code: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  { nationality: "Swazi", code: "SZ", name: "Swaziland" },
  { nationality: "Swedish", code: "SE", name: "Sweden" },
  { nationality: "Swiss", code: "CH", name: "Switzerland" },
  { nationality: "Syrian", code: "SY", name: "Syria" },
  { nationality: "Taiwanese", code: "TW", name: "Taiwan" },
  { nationality: "Tajikistani", code: "TJ", name: "Tajikistan" },
  { nationality: "Tanzanian", code: "TZ", name: "Tanzania" },
  { nationality: "Thai", code: "TH", name: "Thailand" },
  { nationality: "Togolese", code: "TG", name: "Togo" },
  { nationality: "Tokelauan", code: "TK", name: "Tokelau" },
  { nationality: "Trinis ", code: "TT", name: "Trinidad and Tobago" },
  { nationality: "Tongan", code: "TO", name: "Tonga" },
  { nationality: "Tunisian", code: "TN", name: "Tunisia" },
  { nationality: "Turkish", code: "TR", name: "Turkey" },
  { nationality: "Turkmen", code: "TM", name: "Turkmenistan" },
  { nationality: "Belongers", code: "TC", name: "Turks and Caicos Islands" },
  { nationality: "Tuvaluan", code: "TV", name: "Tuvalu" },
  { nationality: "Ugandan", code: "UG", name: "Uganda" },
  { nationality: "Ukrainian", code: "UA", name: "Ukraine" },
  { nationality: "Emirati", code: "AE", name: "United Arab Emirates" },
  { nationality: "British", code: "GB", name: "United Kingdom" },
  { nationality: "American", code: "US", name: "United States" },
  { nationality: "Uruguayan", code: "UY", name: "Uruguay" },
  { nationality: "Uzbekistani", code: "UZ", name: "Uzbekistan" },
  { nationality: "Ni-Vanuatu", code: "VU", name: "Vanuatu" },
  { nationality: "Venezuelan", code: "VE", name: "Venezuela" },
  { nationality: "Vietnamese", code: "VN", name: "Vietnam" },
  {
    nationality: "Wallisian and Futunan",
    code: "WF",
    name: "Wallis and Futuna",
  },
  { nationality: "Sahrawi", code: "EH", name: "Western Sahara" },
  { nationality: "Yemeni", code: "YE", name: "Yemen" },
  { nationality: "Zambian", code: "ZM", name: "Zambia" },
  { nationality: "Zimbabwean", code: "ZW", name: "Zimbabwe" },
];

window.blogs = [
  { id: 1, title: "How Safe is Kenya?", img: plan1 },
  { id: 2, title: "Healthcare in Kenya", img: plan2 },
  { id: 3, title: "The Education System in Kenya", img: plan3 },
  { id: 4, title: "What are the Visa Requirements for Kenya", img: plan4 },
];

window.plan_your_trip_blogs = [
  {
    category: [
      { id: 1, name: "Education" },
      { id: 2, name: "Safety" },
      { id: 3, name: "Healthcare" },
      { id: 4, name: "Visa Requirments" },
    ],
  },
  {
    articles: [
      {
        id: 1,
        title: "The Education System in Kenya",
        category: 1,
        slug: "the-education-system-in-kenya",
        country: "KE",
      },
      {
        id: 1,
        title: "Schools in Kenya",
        category: 1,
        slug: "schools-in-kenya",
        country: "KE",
      },
    ],
  },
];

window.learn = [
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Insurance Glossary - Common Insurance Terms",
    img: learn1,
  },
  {
    id: 2,
    published_on: "29th March 2022",
    title: "How to Choose a Health Insurance Plan",
    img: learn2,
  },
  {
    id: 3,
    published_on: "29th March 2022",
    title: "International Health Vs. Travel Insurance - Which Should I Buy?",
    img: learn3,
  },
];

window.country_blogs = [
  {
    code: "KE",
    content: [
      { id: 1, title: "How Safe is Kenya?", img: plan1 },
      { id: 2, title: "Healthcare in Kenya", img: plan2 },
      { id: 3, title: "The Education System in Kenya", img: plan3 },
      { id: 4, title: "What are the Visa Requirements for Kenya", img: plan4 },
    ],
  },
  {
    code: "TZ",
    content: [
      { id: 1, title: "How Safe is Tanzania?", img: plan1 },
      { id: 2, title: "Healthcare in Tanzania", img: plan2 },
      { id: 3, title: "The Education System in Tanzania", img: plan3 },
      {
        id: 4,
        title: "What are the Visa Requirements for Tanzania",
        img: plan4,
      },
    ],
  },
  {
    code: "UG",
    content: [
      { id: 1, title: "How Safe is Uganda?", img: plan1 },
      { id: 2, title: "Healthcare in Uganda", img: plan2 },
      { id: 3, title: "The Education System in Uganda", img: plan3 },
      { id: 4, title: "What are the Visa Requirements for Uganda", img: plan4 },
    ],
  },
  {
    code: "ZM",
    content: [
      { id: 1, title: "How Safe is Zambia?", img: plan1 },
      { id: 2, title: "Healthcare in Zambia", img: plan2 },
      { id: 3, title: "The Education System in Zambia", img: plan3 },
      { id: 4, title: "What are the Visa Requirements for Zambia", img: plan4 },
    ],
  },
  {
    code: "DEFAULT",
    content: [
      {
        id: 1,
        title: "Access to the best doctors, wherever you are",
        img: plan5,
      },
      {
        id: 2,
        title: "Private medical insurance for your expat employees",
        img: plan7,
      },
      {
        id: 3,
        title: "Out of office? Road to getting international cover",
        img: plan6,
      },
      {
        id: 4,
        title:
          "How stressed are you! What if you knew how much stress is affecting you?",
        img: plan8,
      },
    ],
  },
];

export const articles = [
  {
    code: "KE",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Kenya?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Healthcare in Kenya",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Kenya",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are the Visa Requirements for Kenya",
        img: plan4,
      },
    ],
  },
  {
    code: "TZ",
    content: [
      {
        id: 1,
        category: {
          id: 2,
          name: "Saftey",
        },
        title: "How Safe is Tanzania?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 3,
          name: "Healthcare",
        },
        title: "Healthcare in Tanzania",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Tanzania",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 4,
          name: "Visa Requirments",
        },
        title: "What are the Visa Requirements for Tanzania",
        img: plan4,
      },
    ],
  },
  {
    code: "UG",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Uganda?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 3,
          name: "Healthcare",
        },
        title: "Healthcare in Uganda",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Uganda",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 4,
          name: "Visa requiremnts",
        },
        title: "What are the Visa Requirements for Uganda",
        img: plan4,
      },
    ],
  },
  {
    code: "ZM",
    content: [
      {
        id: 1,
        category: {
          id: 2,
          name: "Saftey",
        },
        title: "How Safe is Zambia?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 3,
          name: "Healthcare",
        },
        title: "Healthcare in Zambia",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Zambia",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 4,
          name: "Visa requirements",
        },
        title: "What are the Visa Requirements for Zambia",
        img: plan4,
      },
    ],
  },
  {
    code: "DEFAULT",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How to get help paying for Medical Insurance",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Education",
        },
        title:
          "How stressed are you! What if you knew how much stress is affecting you?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Do I need Travel Health Insurance",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: travel,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Access to the best doctors, wherever you are",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan5,
      },
      {
        id: 5,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Private medical insurance for your expat employees",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan7,
      },
      {
        id: 6,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What is a high deductible health plan?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan6,
      },
      {
        id: 7,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What is Post-Claims Underwriting?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 8,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are Medical Guidelines?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 9,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How to find a doctor when you are sick",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 10,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Should married couples have separate health insurance?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 11,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Can premium subsidies be used to pay for Dental Insurance?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 10,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How to afford health insurance without subsidized coverage",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
      {
        id: 10,
        category: {
          id: 1,
          name: "Education",
        },
        title:
          "Will I have to pay my deductible before I can get medical care?",
        subcategory: {
          id: 1,
          name: "Education",
        },

        author: "",
        date_pablished: "",
        img: plan8,
      },
    ],
  },
];

export const planArticles = [
  {
    code: "KE",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Kenya?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Healthcare",
        },
        title: "Healthcare in Kenya",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Kenya",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are the Visa Requirements for Kenya",
        img: plan4,
      },
    ],
  },
  {
    code: "TZ",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Tanzania?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Healthcare in Tanzania",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Tanzania",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are the Visa Requirements for Tanzania",
        img: plan4,
      },
    ],
  },
  {
    code: "UG",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Uganda?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Healthcare in Uganda",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Uganda",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are the Visa Requirements for Uganda",
        img: plan4,
      },
    ],
  },
  {
    code: "ZM",
    content: [
      {
        id: 1,
        category: {
          id: 1,
          name: "Education",
        },
        title: "How Safe is Zambia?",
        img: plan1,
      },
      {
        id: 2,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Healthcare in Zambia",
        img: plan2,
      },
      {
        id: 3,
        category: {
          id: 1,
          name: "Education",
        },
        title: "The Education System in Zambia",
        img: plan3,
      },
      {
        id: 4,
        category: {
          id: 1,
          name: "Education",
        },
        title: "What are the Visa Requirements for Zambia",
        img: plan4,
      },
    ],
  },
  {
    code: "DEFAULT",
    content: [
      {
        id: 5,
        category: {
          id: 1,
          name: "Education",
        },
        title:
          "Everything expats need to know before moving to an African country",
        img: plan10,
      },
      {
        id: 6,
        category: {
          id: 1,
          name: "Education",
        },
        title: "Setting up your new home in a foreign country",
        img: plan11,
      },
      {
        id: 7,
        category: {
          id: 1,
          name: "Education",
        },
        title:
          "Find the right healthcare for you and your family before moving",
        img: plan9,
      },
    ],
  },
];

export const siteData = [
  {
    term: "Accident",
    question: "What is an accident",
    link: "what-is-an-accident",
    answer:
      "An unexpected, unforeseen and involuntary external event resulting in injury occurring whilst your policy is in force",
  },
  {
    term: "Acute",
    question: "What is an acute condition",
    link: "what-is-an-acute-condition",
    answer:
      "A medical condition which is brief, has a definite end point and which we, on advice or general advice determine can be cured by treatment",
  },
  {
    term: "Act of terrorism",
    question: "What is an act of terrorism",
    link: "what-is-an-act-of-terrorism",
    answer:
      "An act, including but not limited to the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in conjunction with any organization(s) or government(s), committed for political, religious, ideological or ethnic purposes or reasons including the intention to influence any government and/or to put the public or any section of the public in fear",
  },
  {
    term: "Advice",
    question: "Medical advice",
    link: "medical-advice",
    answer:
      "Any consultation from a medical practitioner or specialist including the issue of any prescriptions or repeat prescriptions.",
  },
  {
    term: "Appliances",
    question: "Medical appliances",
    link: "medical-appliances",
    answer:
      "Devices, implants and equipment when used as an integral part of a surgical procedure administered by a medical practitioner or specialist",
  },
  {
    term: "Benefits",
    question: "What are benefits",
    link: "what-are-benefits",
    answer:
      "The insurance cover provided by this policy and any extensions or restrictions shown in the Policy Schedule or in any endorsements (if applicable).",
  },
  {
    term: "Body Injury",
    question: "What is body injury",
    link: "what-is-body-injury",
    answer:
      "Injury which is caused solely by an accident which results in the insured person's dismemberment, disablement or other physical injury.",
  },
  {
    term: "Chronic",
    question: "Chronic",
    link: "chronic-definition",
    answer:
      "A disease, illness or injury that has at least one of the following characteristics : ",
    list: [
      "it continues indefinitely and has no known cure",
      "it comes back or is likely to come back",
      "it is permanent",
      "You need to be rehabilitated or specially trained to cope with it",
      "It needs long-term monitoring, consultation, checkups examinations or tests",
    ],
  },
  {
    term: "Claim",
    question: "Claim definition",
    link: "claim",
    answer:
      "This is a bill. Either you or your healthcare provider can send this bill asking for reimbursement for the amount covered by the insurer.",
  },
  {
    term: "Coinsurance",
    question: "What is coinsurance",
    link: "what-is-coinsurance",
    answer:
      "The percentage of the total value of the incurred expenses for which the policyholder/insured person is responsible",
  },
  {
    term: "Congenital anomaly",
    question: "What is congenital anomaly",
    link: "what-is-congenital-anomaly",
    answer:
      "Any genetic, physical, or biochemical (metabolic) defect, disease, or malfunction, and which may not be obvious at birth.",
  },
  {
    term: "Continuous transfer terms",
    question: "What are continuous transfer terms ",
    link: "what-are-continuous- transfer-terms ",
    answer:
      "The acceptance by us of your original date of entry as shown by your current insurer will be applied to your policy with us. We will maintain your existing underwriting or special acceptance terms, as offered by your existing insurer, such as any moratoria or specific exclusions and your policy with us will be governed by the terms and conditions of our policy. Any transfer will be subject to no enhanced benefits being provided. We reserve the right at all times to decline a continuous transfer terms application without giving any reason.",
  },
  {
    term: "Co-ops",
    question: "Co-ops Definition",
    link: "co-ops-definition",
    answer:
      "These are insurance organizations owned by the people they insure. They are generally more affordable than private health insurance as they are not profit-driven organizations",
  },
  {
    term: "Copay",
    question: "Copay definition",
    link: "copay-definition",
    answer:
      "This is a fixed out-of-pocket expense you pay for services your insurer covers",
  },
  {
    term: "Country of nationality",
    question: "Country of nationality ",
    link: "country-of-nationality ",
    answer: "The country for which you hold a passport",
  },
  {
    term: "Critical",
    question: "Critical ",
    link: "critical-medical-condition",
    answer:
      "A medical condition that is, in our reasonable opinion, unstable and serious, where the outcome cannot be medically predicted, the prognosis is uncertain and the person may die",
  },
  {
    term: "Day patient",
    question: "Who is a day patient",
    link: "day-patient",
    answer:
      "An insured person who is admitted to a hospital bed but does not stay overnight.",
  },
  {
    term: "Deductible",
    question: "Deductible definition",
    link: "deductible",
    answer:
      "This is the amount you must spend before your insurer begins paying their part towards your cover",
  },
  {
    term: "Dental practitioner",
    question: "Dental practitioner",
    link: "dental-practitioner",
    answer:
      "A person who is licensed by the relevant licensing authority to practise dentistry in the country where the dental treatment is given.",
  },
  {
    term: "Lifetime Limit",
    question: "Lifetime Limit definition",
    link: "lifetime-limit",
    answer:
      "This is the maximum amount your insurer will pay for your cover. When the cost exceeds this limit, you then have to pay out of pocket.",
  },
  {
    term: "Premium",
    question: "Premium definition",
    link: "premium",
    answer: "This is the amount you pay to keep your insurance policy active.",
  },
  {
    term: "Drugs and dressings",
    question: "Drugs and dressings definition",
    link: "drugs-and-dressings",
    answer:
      "Essential drugs dressings and medicines prescribed by a medical practitioner or specialist and which are not available without prescription.",
  },
  {
    term: "Elective",
    question: "Elective definition",
    link: "elective",
    answer:
      "Planned treatment which is medically necessary,but which is not required in an emergency.",
  },
  {
    term: "Emergency",
    question: "Emergency definition",
    link: "emergency",
    answer:
      "A sudden,serious, and unforeseen acute medical condition or injury requiring immediate medical care.",
  },
  {
    term: "Hereditary",
    question: "Hereditary definition",
    link: "hereditary",
    answer: "A disease or disorder that is inherited genetically.",
  },
  {
    term: "Hospital",
    question: "Hospital definition",
    link: "hospital",
    answer:
      "An establishment that is legally licensed as a medical or surgical hospital under the laws of the country in which it is situated.",
  },
  {
    term: "Inpatient",
    question: "Inpatient definition",
    link: "inpatient",
    answer:
      "An insured person who stays in a hospital bed and is admitted for one or more nights solely  to receive treatment.",
  },
  {
    term: "Insured person/you/your",
    question: "Insured person/you/your definition",
    link: "insured-person-you-your",
    answer:
      "The policyholder and /or the dependants named on the Policy Schedule.",
  },
  {
    term: "Local national",
    question: "Local national definition",
    link: "local-national",
    answer:
      "Any persons living or working in the country for which they hold a passport for a period of cover.",
  },
  {
    term: "Medical condition",
    question: "Medical condition definition",
    link: "medical-condition",
    answer: "Any injury,illness or disease,including psychiatric illness.",
  },
  {
    term: "Medical practitioner",
    question: "Medical practitioner definition",
    link: "medical-practitioner",
    answer:
      "A person who has attained primary degrees in medicine or surgery by attending a medical school recognised by the World Health Organization and who is licensed by the relevant authority to practice medicine in the country where the treatment is  given.",
  },
  {
    term: "Medically necessary",
    question: "Medically necessary definition",
    link: "medical-necessary",
    answer:
      "A medical service or treatment,which in the opinion of a qualified medical practitioner is appropriate and consistent with the diagnosis and which in accordance with generally accepted medical standards could not have been omitted without  adversely affecting the insured person’s condition or the quality of medical care rendered.",
  },
  {
    term: "Organ transplant",
    question: "Organ transplant definition",
    link: "organ-transplant",
    answer:
      "The replacement of vital organs (including bone marrow) as consequences of an underlying eligible medical condition.",
  },
  {
    term: "Outpatient",
    question: "Outpatient definition",
    link: "outpatient",
    answer:
      "An insured person who receives treatment at a recognized medical facility, but is not admitted to a hospital bed as an inpatient or day patient.",
  },
  {
    term: "Palliative treatment",
    question: "Palliative treatment definition",
    link: "palliative-treatment",
    answer:
      "Any treatment given, on advice or general advice, for the purpose of offering temporary relief of symptoms. Palliative treatment is not given to cure the medical condition causing the symptoms. For the purposes of this policy, palliative treatment will include renal dialysis.",
  },
  {
    term: "Private Room",
    question: "Private Room definition",
    link: "private-room",
    answer: "Single occupancy accommodation in a private hospital.",
  },
  {
    term: "Qualified nurse",
    question: "Qualified nurse definition",
    link: "qualified-nurse",
    answer:
      "A nurse whose name is currently on any register or roll of nurses, maintained by any Statutory Nursing Registration Body within the country in which they are resident.",
  },
  {
    term: "Reasonable and Customary Charges",
    question: "Reasonable and Customary Charges definition",
    link: "reasonable-and-customary-charges",
    answer:
      "The average amount charged in respect of valid services or treatment costs, as determined by our experience in any particular country, area or region and substantiated by an independent third party, being a practising surgeon/medical practitioner/specialist or government health department.",
  },
  {
    term: "Related Condition",
    question: "Related Condition definition",
    link: "related-condition",
    answer:
      "Any injuries, illness or diseases are related conditions if we, on general advice, determine that one is a result of the other or if each is a result of the same injury, illness or disease.",
  },
  {
    term: "Rehabilitation",
    question: "Rehabilitation definition",
    link: "rehabilitation",
    answer:
      "Assisting an insured person who, following a medical condition, requiring physical therapy and assistance in independent living to restore them, as much as medically necessary or practically able, to the position in which they were in prior to such medical condition occurring.",
  },
  {
    term: "Sound Natural teeth",
    question: "Sound Natural teeth definition",
    link: "sound-natural-teeth",
    answer:
      "Teeth that were stable, functional, free from decay and advanced periodontal disease, and in good repair at the time of the accident.",
  },
  {
    term: "Specialist",
    question: "Specialist definition",
    link: "specialist",
    answer:
      "A registered medical practitioner who currently holds a substantive consultant appointment in that speciality, which is recognised as such by the statutory bodies of the relevant country.",
  },
  {
    term: "Treatment",
    question: "Treatment definition",
    link: "treatment",
    answer:
      "Surgical, medical or other procedures the sole purpose of which is the cure or relief of a medical condition.",
  },
];

export const Frequently_Asked_Questions = [
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you offer Travel Cover as a stand-alone policy?",
    description:
      "Most insurers offer Travel Plan as an add-on to the medical policy and cannot be purchased as standalone. The Travel add-on covers worldwide trips up to 180 days at a time. Some of the benefits include: Inpatient, daycare and outpatient treatment needed for any one or more medical conditions you suffer during a trip, Loss of deposits, cancellation or curtailment, Travel delays, Missed departures and travel disruption, Hijack, Delayed baggage, Loss of passport and travel documents.However, some insurers like Bupa offer standalone travel policies without you having the annual medical policy.",
  },
  {
    id: 2,
    published_on: "29th March 2022",
    title: "Do you cover pre‐existing/exsiting pregnancy?",
    description:
      "If an applicant is pregnant at the time of purchasing a policy, insurers don’t cover the ongoing pregnancy unless you are part of a group with Medical History Disregarded underwriting.",
  },
  {
    id: 3,
    published_on: "29th March 2022",
    title: "Do you have plans that cover pre-existing conditions?",
    description:
      "Full Medical Underwriting (FMU) option is available to individuals with pre-existing medical conditions which they want to be covered from inception date. To apply for this option, members will need to submit a full medical declaration for underwriters assessment. The insurer will agree to either accept the pre-existing medical condition and may charge an increased premium, or continue to exclude the said pre-existing medical condition, or decline cover altogether. Medical History Disregarded (MHD) is the option available only to group Schemes of 10 or more Employees. MHD covers pre-existing medical conditions for members in a group and attracts an extra premium loading. To apply for this option, the group administrator is required to submit a Declaration of Material Facts about the members with the conditions to be covered.",
  },
  {
    id: 4,
    published_on: "29th March 2022",
    title: "Do you provide cover in the USA?",
    description:
      "Our insurance partners provide cover for elective treatment in the USA under Worldwide plans. However, due to the Affordable Care Act (ACA), which is regulated by the US government we are unable to offer our Africa products for members based in the USA for more than 183 days (consecutively or otherwise) in a period of 3 policy years at the current time. Kindly contact our offices for further information on the same. However, insurers offer a limited cover for Accident and Emergency treatment required in the USA under plans where USA is not within the Area of Cover. Please write to us to know more.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Is COVID-19 covered under your plan?",
    description:
      "Yes, medically necessary diagnosis and treatment of COVID-19 is covered across all our plans in line with the Policy terms and conditions.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "In case of an emergency, how do I contact Policy Expert?",
    description:
      "Kindly visit our contacts page for all our numbers and physical location.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you cover emergency evacuation?",
    description:
      "We cover evacuation of an insured person in the event of emergency treatment not being readily available at the place of the incident, to an appropriate medical facility in an alternative suitable country, within the geographical area of coverage, for the purpose of admission to hospital as an inpatient or day patient. Extended to cover the costs for one other person to travel with the insured person as an escort.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you cover costs for the accompanying person in Evacuation?",
    description:
      "Yes, during evacuation of an insured person in the event of emergency treatment not being readily available at the place of the incident, the benefit is extended to cover the costs for one other person to travel with the insured person as an escort.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Who partners with you to offer evacuation?",
    description:
      "Our Insurance partners work with all the reputable evacuation providers across the world. This list is regularly updated as and when changes occur.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "What’s the claims procedure?",
    description:
      "For Planned Inpatient & Day Patient Treatment - Pre-authorisation must be obtained to confirm the treatment is covered and to enable the insurer to arrange Direct Billing with the Respective Service Provider. Please email a completed Pre-authorisation form, medical report and estimated cost of Doctor/Specialist seven days prior to elective treatment date.For any emergency, please call insurer’s 24*7 number mentioned on your membership card. Where outpatient treatment is undertaken on a pay and claim basis you must submit a correctly completed claims form attaching all relevant supporting documentation. All claims must be submitted no later than 180 days after the treatment date. All correct and complete submitted claims will be settled within 30 days.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title:
      "What if I don’t claim the entire policy year, on my renewal can I get a “no claims discount”?",
    description:
      "Unfortunately we do not have the “no claims discount” available currently.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do I require pre-authorization for every treatment?",
    description:
      "You must seek Pre-authorisation prior to commencing the following treatments: planned Inpatient or day patient treatment(hospitalisation), any pregnancy or childbirth treatment, planned surgery, evacuation/ out of country transportation, second medical opinions, psychiatric treatment, home nursing charges, planned CT, MRI & PET Scans, and alternative medicine where treatment exceed US $ 250.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title:
      "Can I get Direct Settlement in all countries within my Area of coverage?",
    description:
      "Yes, for Planned Inpatient & Day Patient Treatment direct billing can be arranged upon Pre-authorisation within the Area of Coverage. However for outpatient treatment, direct settlement is available for a few plan options.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title:
      "Do I always have to use the providers and specialists in your panel?",
    description:
      "You have the liberty to use any medical specialists of your choice. However, for providers outside Insurer’s list you may need to make an out-of-pocket payment for your treatment and then submit a claim for reimbursement.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title:
      "How do you operate in countries where you have no physical location?",
    description:
      "We have offices in several key locations around the world, however not in every country just like every multinational corporation. However, our insurance partners work with a number of regional business associates so that it has a worldwide capability. For more information, please visit respective insurer’s website.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Can you offer cover for six months?",
    description:
      "Please note our contracts are Annual and benefits are applicable for a period of one year.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you cover birth defects?",
    description:
      "Newly diagnosed birth defects are covered under our Congenital anomalies benefit.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Can I be covered with my dependants on the same policy?",
    description:
      "Yes, our plans cover one spouse or adult partner, children who are under 18 years of age and residing with you, and children up to 26 years age if they are in full-time education.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you offer corporate covers?",
    description:
      "We provide cover to groups / corporates of 3 and above employees, kindly email our office to enable us assist you.",
  },
  {
    id: 1,
    published_on: "29th March 2022",
    title: "Do you cover costs associated with IVF?",
    description:
      "A few insurers like Aetna and Bupa don’t cover infertility including IVF. However, some high end plans from other insurers (VUMI, Generali) cover infertility up to a lifetime limit 25,000 USD.",
  },
];

export const partners = [
  {
    img: aetna,
    description:
      "Aetna International is a part of Aetna, a Fortune 100 company.They are world leaders in providing health care benefits with a 160-year heritage of strength and expertise.Its award-winning expatriate health insurance business provides health benefits to more than 800,000 members worldwide.",
  },
  {
    img: nhi,
    description:
      "Now Health is a dedicated medical insurance. It aims to provide revolutionary service and operation capabilities, benefit-rich plans and exceptional customer service.Whilst headquartered in Hong Kong, Now Health also has regional service centres in Shanghai, the United Kingdom, and Dubai.",
  },
  {
    img: allianz,
    description:
      "Allianz Care as the international health and life division of Allianz Partners, has a focus on addressing the health and wellbeing protection needs of clients worldwide, through the delivery of straightforward, innovative solutions that make each client feel understood, confident and valued.",
  },
  {
    img: bupa,
    description:
      "Since starting in the UK in 1947 with just 38,000 members, Bupa has developed into an internationally respected brand. Bupa Global was established in 1971 to extend our health cover to expatriates around the globe, with over 1.2 million medical providers.",
  },
  {
    img: axa,
    description:
      "AXA Global Healthcare, a part of the AXA Group, is one business dedicated to global healthcare and nothing else. Over 55 years, AXA has been helping people who live and work internationally to access the healthcare they need.",
  },
  {
    img: generali,
    description:
      "Generali Worldwide is a wholly-owned subsidiary of the Generali Group. It provides comprehensive Medical, Dental, Vision and Life coverage for employees and their families all around the world. It is committed to supporting companies and other groups with expatriate employees worldwide",
  },
  {
    img: vumi,
    description:
      "VIP Universal Medical Insurance Group (VUMI) is an international health insurance company offering exclusive major medical insurance products and VIP medical services to private and corporate clients worldwide. VUMIproducts are designed to give you unique benefits and extensive global coverage.",
  },
];
