import React from "react";
import Navigation from "../components/layout/Navigation";
// import aetna from "../img/aetna.png";
import nhi from "../img/nhi.png";
import allianz from "../img/allianz.png";
import bupa from "../img/bupa.png";
import axa from "../img/axa.png";
import vumi from "../img/vumi.jpg";
import globhopper from "../img/globhopper.png";
import morgan_price from "../img/morgan_price.png";
import william_russell from "../img/william_russell.png";

const Partners = () => {
  const partners = [
    {
      img: globhopper,
      description:
        "IMG is an award-winning provider of global insurance benefits and assistance services. For more than 25 years, we have enabled our members to worry less and experience more by delivering the protection they need, backed by the support they deserve.",
    },
    {
      img: nhi,
      description:
        "Now Health is a dedicated medical insurance. It aims to provide revolutionary service and operation capabilities, benefit-rich plans and exceptional customer service.Whilst headquartered in Hong Kong, Now Health also has regional service centres in Shanghai, the United Kingdom, and Dubai.",
    },
    {
      img: allianz,
      description:
        "Allianz Care as the international health and life division of Allianz Partners, has a focus on addressing the health and wellbeing protection needs of clients worldwide, through the delivery of straightforward, innovative solutions that make each client feel understood, confident and valued.",
    },
    {
      img: bupa,
      description:
        "Since starting in the UK in 1947 with just 38,000 members, Bupa has developed into an internationally respected brand. Bupa Global was established in 1971 to extend our health cover to expatriates around the globe, with over 1.2 million medical providers.",
    },
    {
      img: axa,
      description:
        "AXA Global Healthcare, a part of the AXA Group, is one business dedicated to global healthcare and nothing else. Over 55 years, AXA has been helping people who live and work internationally to access the healthcare they need.",
    },
    {
      img: vumi,
      description:
        "VIP Universal Medical Insurance Group (VUMI) is an international health insurance company offering exclusive major medical insurance products and VIP medical services to private and corporate clients worldwide. VUMIproducts are designed to give you unique benefits and extensive global coverage.",
    },
    {
      img: morgan_price,
      description:
        "Morgan Price International is an award winning provider of healthcare insurance to local nationals and expatriates around the world.  For over 20 years we have been giving our intermediary partners and clients the very best service and products with a view to giving them peace of mind when in challenging or difficult situations.  Morgan Price has offices in the UK, Denmark, Dubai and Thailand.”",
    },
    {
      img: william_russell,
      description:
        "William Russell is an award winning provider of healthcare insurance to local nationals and expatriates around the world.  For over 20 years we have been giving our intermediary partners and clients the very best service and products with a view to giving them peace of mind when in challenging or difficult situations.  Morgan Price has offices in the UK, Denmark, Dubai and Thailand.”",
    },
  ];
  return (
    <>
      <div className="page">
        <Navigation />
        <div className="page position-relative">
          <section className="page container">
            <div className="pt-5 pb-3 text-dark">
              <h4 className="font-weight-bold">Our Partners</h4>
            </div>
            <div className="row mb-5">
              {partners.map((d, i) => (
                <div className="col-md-4" key={i}>
                  <div className="card p-3 partner-card my-3">
                    <div>
                      <img src={d.img} alt="" />
                    </div>
                    <div className="text-mute">{d.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Partners;

// import React, { Component } from "react";
// import Footer from "../components/footer";

// import aetna from "../img/aetna.png";
// import nhi from "../img/nhi.png";
// import allianz from "../img/allianz.png";
// import bupa from "../img/bupa.png";
// import axa from "../img/axa.png";
// import generali from "../img/generali.png";

// class Partners extends Component {
//   state = {
//     partners: [
//       {
//         img: aetna,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//       {
//         img: nhi,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//       {
//         img: allianz,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//       {
//         img: bupa,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//       {
//         img: axa,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//       {
//         img: generali,
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies tortor commodo eleifend fermentum. Maecenas tortor nibh, laoreet egestas neque eget, volutpat ultrices.",
//       },
//     ],
//   };
//   render() {
//     return (
//       <div>
//         <section className="page container">
//           <div className="pt-5 pb-3 text-dark">
//             <h4 className="font-weight-bold">Our Partners</h4>
//           </div>

//           <div className="row">
//             {this.state.partners.map((d, i) => (
//               <div className="col-md-4" key={i}>
//                 <div className="card p-3 partner-card my-3">
//                   <div>
//                     <img src={d.img} alt="" />
//                   </div>
//                   <div className="text-mute">{d.description}</div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </section>

//         <Footer></Footer>
//       </div>
//     );
//   }
// }

// export default Partners;
