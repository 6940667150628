import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/layout/Navigation";
import { ChevronRight, ChevronsRight } from "react-feather";
import { sideblogs } from "../utils/data";

const WebsiteSearchResults = (props) => {
  const getSuggestions = (search) => {
    console.log(search);
  };

  useEffect(() => {
    getSuggestions(props.match.params.q);

    return () => {
      // cleanup
    };
  }, [props.match.params.q]);

  return (
    <>
      <div className="page faq">
        <Navigation />
        <header className="bg-primary text-white">
          <div className="container py-5">
            <div className="col-md-12">
              <div className="website-search-header">
                <h3>
                  Showing results for: <span>"{props.match.params.q}"</span>
                </h3>
              </div>
            </div>
          </div>
        </header>
        <section className="container text-dark py-5">
          <div className="row">
            <div className="col-md-12">
              {sideblogs.map((s, i) => (
                <div className="result mb-5" key={i}>
                  <h5>
                    <strong>
                      <u>{s.title}</u>
                    </strong>
                  </h5>
                  <span>
                    <Link to="/">
                      Home&nbsp;
                      <ChevronRight size={10} />
                    </Link>
                    &nbsp;
                    <Link to="/plan-your-trip">
                      Plan your trip&nbsp;
                      <ChevronRight size={10} />
                    </Link>
                    &nbsp;{s.title}
                  </span>
                  <p className="mb-2">
                    {s.content}&nbsp;...
                    <Link to="">
                      <u>
                        Read full article&nbsp;
                        <ChevronsRight size="13" />
                      </u>
                    </Link>
                    <br />
                    <span style={{ fontSize: "0.9em" }}>
                      published on 13 Nov 2021
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WebsiteSearchResults;
