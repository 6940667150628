import CryptoJS from "crypto-js";
import aetna from "../img/logo_sm/aetna.png";
import allianz from "../img/logo_sm/allianz.png";
import axa from "../img/logo_sm/axa.png";
import bupa from "../img/logo_sm/bupa.png";
import generali from "../img/logo_sm/generali.png";
import nhi from "../img/logo_sm/nhi.png";
import vumi from "../img/logo_sm/vumi.png";
import globhopper from "../img/logo_sm/globhopper.png";
import morgan_price from "../img/logo_sm/morgan_price.png";
import william_russell from "../img/logo_sm/william_russell.png";
import Cookies from "js-cookie";
import KE_Flag from "../assets/flags/ke.png";
import TZ_Flag from "../assets/flags/tz.png";
import UG_Flag from "../assets/flags/ug.png";

export const east_african_flags = [
  { name: "Kenya", code: "ke", flag: KE_Flag },
  { name: "Uganda", code: "ug", flag: UG_Flag },
  { name: "Tanzania", code: "tz", flag: TZ_Flag },
];

export const addCookie = (payload, cookie_name) => {
  var date = new Date();
  var minutes = 31;
  date.setTime(date.getTime() + minutes * 60 * 10000);

  Cookies.set(cookie_name, payload, {
    expires: date,
  });
};

export const getCookie = (name) => {
  const the_cookies = Cookies.get(name);

  return the_cookies;
};

export const deleteCookie = () => {
  var cookie = document.cookie.split(";");

  for (var i = 0; i < cookie.length; i++) {
    var chip = cookie[i],
      entry = chip.split("="),
      name = entry[0];

    Cookies.remove(name);

    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
};

export const sitePassword = "6tpu_x56@23";

export const geImage = (image) => {
  if (image === "nhi.png") {
    return nhi;
  }

  if (image === "aetna.png") {
    return aetna;
  }

  if (image === "vumi.png") {
    return vumi;
  }

  if (image === "allianz.png") {
    return allianz;
  }

  if (image === "generali.png") {
    return generali;
  }

  if (image === "bupa.png") {
    return bupa;
  }

  if (image === "axa.png") {
    return axa;
  }

  if (image === "img.png") {
    return globhopper;
  }

  if (image === "morgan_price.png") {
    return morgan_price;
  }

  if (image === "william_russell.png") {
    return morgan_price;
  }
};

export const chengeToLowerCase = (name) => {
  return name.toLowerCase();
};

export const findLogoPic = (str) => {
  let value = str.split("/").pop();

  return value;
};

export const removeDollarComma = (value) => {
  if (value === "unlimited") {
    return 10000000;
  } else {
    const nodollar = value.replace(/\$/g, "");
    const nocomma = nodollar.replace(/,/g, "");

    return nocomma;
  }
};

export const separator = (numb) => {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

export const baseURL =
  "https://portal.micglobalrisks.com:8082/policyxpert/v1/api";

export const salesforcePushLeadURL =
  "https://cors-anywhere.herokuapp.com/https://executivehealthcarelimitedkenya--develop.sandbox.lightning.force.com/services/apexrest/PolicyExpert";

// ("{{OrgURL}}/services/apexrest/PolicyExpert");

const grant_type = "password";
const client_id =
  "3MVG954MqIw6FnnMBZcbkJAWMYIotAMiDfD06t2WId9T.zACmTi3OAuX27ZktaELoFuyra6sEe5G.orp6XoBB";
const client_secret =
  "F869FB956452ACA1370C04E47E71AD7E60C3BCA13EEACE6D51154597CEE62968";
const username = "sfadmin@exechealth.co.za.develop";
const password = "Execaaded8c4c";

export const salesForceToken = `https://test.salesforce.com/services/oauth2/token?grant_type=${grant_type}&client_id=${client_id}&client_secret=${client_secret}&username=${username}&password=${password}`;

export const pe_cms = "http://localhost:81/cms/api";

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const verifyLoad = (value, ms = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), ms);
  });
};

export const encryptData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET
  ).toString();

  return ciphertext;
};

export const decryptData = (ciphertext) => {
  var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

export const testJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const addCommas = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const sanitizeBookName = (name) => {
  return name.replace(/\s+/g, "-").toLowerCase();
};

export const getNumberofWords = (description) => {
  const words = description.length;

  return words;
};

export const capitalizeFirstLetter = (str) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const shortenString = (string, length = 0) => {
  return string.substring(0, length);
};

export const mapRelation = (value) => {
  if (value === 3) {
    return "Son";
  } else if (value === 4) {
    return "Daughter";
  } else {
    return "Spouse";
  }
};
