import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "popper.js";
import "bootstrap/dist/css/bootstrap.css";

import "./fonts/fonts.css";
import "./css/responsive.scss";
import "alertifyjs/build/css/alertify.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-toastify/dist/ReactToastify.min.css";
// import "./doc/scss/master.scss";
import "font-awesome/css/font-awesome.min.css";
import "swiper/swiper.scss";
import "react-modal-video/scss/modal-video.scss";

ReactDOM.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
