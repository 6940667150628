import React from "react";
import Navigation from "../components/layout/Navigation";
import MoreInformation from "../components/reuseables/MoreInformation";

const TermsAndConditions = () => {
  return (
    <div className="page">
      <Navigation />
      <div className="container my-5 text-dark">
        <div className="row space_me">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h3 className="bold">Terms &amp; Conditions</h3>
            <hr className="my-4" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="space_me">
              <p>
                Use of this website is permitted with your agreement to comply
                with and be bound by all of the following terms and conditions
                without reservation. These ‘Terms of Use’ and the following
                'Privacy Statement' govern all use of and access to
                pacificprime.com. From here out, "we/us" shall refer to and
                represent Policy Xpert the company, while "The/This Website"
                shall represent policy-xpert.com along with it's pages, texts,
                images and content.
              </p>
              <p>
                You may not access or use this website if you refuse to be bound
                by these 'Terms of Use'. Terms and conditions may at any time be
                revised, and all revisions become effective immediately. Any
                continued use of this website shall be considered to be an
                acceptance of the newly revised terms and conditions. You
                furthermore accept the posting of any amended terms and
                conditions by us to this website as sufficient notification of
                any such change, as revisions may be made without direct
                notification to you.
              </p>
              <p>
                Any unlawful or void provisions found within these 'Terms of
                Use' shall be deemed invalid without effect to the validity or
                enforceability of the remaining provisions. Please send your
                questions via email to info@policy-xpert.com if you have any
                issues with these terms and conditions.
              </p>
              <p>
                Insurance on this website is arranged through licensed insurers.
                Policy Xpert Limited ("PXL") is a registered member of the{" "}
                <b>[ insert insurance body]</b>. Policy Xpert is wholly operated
                and owned by <b>[insert ownership]</b>.
              </p>
            </div>
            <div className="space_me">
              <p>
                Use of this website is permitted with your agreement to comply
                with and be bound by all of the following terms and conditions
                without reservation. These ‘Terms of Use’ and the following
                'Privacy Statement' govern all use of and access to
                pacificprime.com. From here out, "we/us" shall refer to and
                represent Policy Xpert the company, while "The/This Website"
                shall represent policy-xpert.com along with it's pages, texts,
                images and content.
              </p>
              <p>
                You may not access or use this website if you refuse to be bound
                by these 'Terms of Use'. Terms and conditions may at any time be
                revised, and all revisions become effective immediately. Any
                continued use of this website shall be considered to be an
                acceptance of the newly revised terms and conditions. You
                furthermore accept the posting of any amended terms and
                conditions by us to this website as sufficient notification of
                any such change, as revisions may be made without direct
                notification to you.
              </p>
              <p>
                Any unlawful or void provisions found within these 'Terms of
                Use' shall be deemed invalid without effect to the validity or
                enforceability of the remaining provisions. Please send your
                questions via email to info@policy-xpert.com if you have any
                issues with these terms and conditions.
              </p>
              <p>
                Insurance on this website is arranged through licensed insurers.
                Policy Xpert Limited ("PXL") is a registered member of the{" "}
                <b>[ insert insurance body]</b>. Policy Xpert is wholly operated
                and owned by <b>[insert ownership]</b>.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">PXL Remuneration</h5>
              <p>
                Policy Xpert Limited is remunerated for its services by the
                receipt of commission paid by insurers. Your agreement to
                proceed with insurance through us shall constitute your consent
                to the receipt of commission by the company.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Limitation of Liability</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Availability and Accuracy</h5>
              <p>
                Policy Xpert does not guarantee defects to be remedied or a
                virus-free website if technical matters interrupt or cause
                errors in attempts to access this website. All content and
                information on this website was accurate at the time of
                publication and sourced and collected from publicly available
                locations. Any materials provided are on an 'as is' basis. None
                of the information, content, tools, materials or uploaded
                articles on this website constitute an official offer of
                insurance to you.
              </p>
              <p>
                Any use of materials on this website is done so at the user's
                own risk and we are not liable for any damage caused to
                property, loss of data, computer viruses, infections or any
                other forms of malicious software as a result. Should an
                unauthorized third party edit or alter any content or material
                on this website we will accept no liability to such damage
                caused. We do not make any representation regarding the full
                functionality or reliability of the materials on this website.
              </p>
              <p>
                Maintenance or any other necessary repairs may limit or suspend
                access to the website without warning or notice to you.
                Complying with local laws that are applicable to you in the use
                and access of this website are your responsibility and you are
                forbidden from making any prohibited transactions of products or
                services offered on this website. Policy Xpert will not be held
                liable should any of your own content or material be lost when
                uploading it through this website. We do not offer a guarantee
                on the availability or the provision of any suggested product
                and retain the right to withdraw or reject an application for
                any policy. Any errors on found on this website cannot be used
                to hold Policy Xpert liable for any direct or indirect economic
                losses or loss of revenues, data, profits or damage suffered.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">No Advice</h5>
              <p>
                This website does not provide advice of any kind whether
                personal, financial or medical and no information on this
                website is sensitive to your specific financial or insurance
                state. Information on this website is for general use only and
                is not suitable for diagnostic purposes or to cure or treat any
                medical condition. Discuss with your doctor if you have any
                questions regarding a medical condition. You are recommended to
                seek independent advice concerning your position before using
                the information from this website.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Website Links</h5>
              <p>
                Policy Xpert makes no representations about any other websites
                that you access through this website or websites that link
                through this one. Links may not be made to this website without
                approval from us -please make requests at info@policy-xpert.com.
              </p>
              <p>
                Links accessible through this website are to locations that may
                not be under the control of Policy Xpert , and you understand
                that all dealings with third-party website operators shall be on
                the terms and conditions of the third party operator. Visiting
                any external links is done so at your own risk. Terms and
                conditions found on third party websites may not align with the
                'Terms and Use' for use of this website, and we are not
                responsible for any loss or damage suffered through the use of
                those websites or their pages.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Ownership and Limitation of Use</h5>
              <p>
                Except with the express permission of Policy Xpert , you are not
                allowed to upload, post, publish, reproduce, transmit or
                distribute in any way any component of this website itself or
                create derivative works with respect thereto. No part of this
                website may be displayed in public in any form without our
                consent. All intellectual property rights of this website belong
                to, or have been licensed to Policy Xpert , it's associated
                companies and affiliates. It is an infringement on the rights of
                the licensee should any person use any image, logo, name or
                trademark licensed by Policy Xpert . The “Policy Xpert ” name
                and the logo are trademarks and property of Policy Xpert .
              </p>
              <p>
                Comments posted onto the pages or under content on this website
                are property of Policy Xpert and may be used without payment or
                notification to you. Intent to deny service to or corrupt,
                damage or otherwise interfere with any pages on the website
                shall result in the necessary legal action to protect our
                website and intellectual property rights.
              </p>
              <p>
                If you choose to use this website to obtain insurance quotes for
                your immediate family this must be done with their full consent.
                Queries on pricing related to insurance transactions may be
                carried out for immediate family members and friends.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Indemnity</h5>
              <p>
                Users agree to indemnify Policy Xpert for any and all loss
                suffered and for any costs incurred as a consequence of any
                breach to these terms and conditions. We are entitled to enforce
                this right at any time, even after the immediate event, in
                respect of any such breach or any subsequent breach of these
                terms and conditions.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Applicable Laws and Jurisdiction</h5>
              <p>
                These 'Terms of Use' shall be governed and construed in
                accordance with the laws of The Republic of Kenya. In the event
                of controversy or dispute arising from the use of this website,
                you agree to submit to the jurisdiction of the Kenyan courts.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">
                Privacy Statement - Personal Information Collection Statement
                (PICS){" "}
              </h5>
              <p>
                In order for a successful transaction we require some personal
                information for the following:
              </p>
              <ol>
                <li className="mb-3">Application process</li>
                <li className="mb-3">
                  Administration of insurance policies, insurance claims and
                  medical and security underwriting checks and finally payment
                  process
                </li>
                <li className="mb-3">Notification of confirmation for you;</li>
                <li className="mb-3">
                  Providing insurance or financial services related promotional
                  information;
                </li>
                <li className="mb-3">
                  Disclosure requirements required by the law/regulatory
                  authorities.{" "}
                </li>
              </ol>
              <p>Personal data may also be shared with the following:</p>
              <ol>
                <li className="mb-3">Insurance companies</li>
                <li className="mb-3">Insurance agents</li>
                <li className="mb-3">Third party administrators</li>
                <li className="mb-3">
                  Third party service providers including legal advisors,
                  investigators, loss adjusters, surveyors, specialists,
                  repairers, accountants and data processors
                </li>
                <li className="mb-3">
                  Any person we are obligated to disclose information to under
                  the requirements laws binding us or any of our associated
                  companies for the purpose of any regulations, codes or
                  guidelines issued by governmental, regulatory or other
                  authorities with which we are expected to comply
                </li>
                <li className="mb-3">Requests pursuant to the law.</li>
              </ol>
              <p>
                The Personal Data (Privacy) Ordinance grants you the right to
                see or edit any of your own personal information that we hold.
                If you wish to do either please write us at
                info@policy-xpert.com. The Ordinance grants the right to charge
                a reasonable fee for any requests to access or correct any
                personal information.
              </p>
              <p>
                Collected personal data including your address, phone number,
                email and name may be used to improve the promotion of other
                insurance services and products within your consent. Tick the
                box at the bottom of this PICS if you do not consent to receive
                any marketing information.
              </p>
              <p>
                Please send us a copy of this Notice at the address above if you
                do not wish your personal data be used for the Voluntary
                purposes listed above. If you do not send an “opt-out” request
                we shall treat the continuation of your policy as agreement to
                continue using your personal data for voluntary marketing
                purposes. To withdraw your consent or to stop receiving
                information please contact info@policy-xpert.com.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Anti-Spam Policy</h5>
              <p>
                We have a strict policy against spamming. Spam is an unsolicited
                email, or UCE (Unsolicited Commercial Email). We forbid the
                sending of mass emails or unsolicited emails of any kind. Only
                by sending emails to those who have requested to receive it, are
                you following accepted permission-based email guidelines.
              </p>
              <p>
                No message filtering system is 100% accurate and from time to
                time, legitimate messages will be filtered-out. If you believe
                this has happened to a message you have sent, please advise the
                message recipient by another means. If you suspect that Policy
                Xpert has been used by someone to send spam, report the activity
                to us at info@policy-xpert.com and we will investigate the
                matter further. We may amend this anti-spam policy at any time
                by publishing a new version on this website.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Confirmation of Insurance</h5>
              <p>
                Submission of your information does not constitute an acceptance
                of your request or confirmation of an offer. We will communicate
                when your offer to purchase a product enters our records, and
                whether your request is accepted or rejected. Notification of
                confirmation of any insurance policy may be issued
                electronically or on paper. A binding contract is not reliant
                upon you receiving a notification on paper.
              </p>
              <p>
                The information on products and services offered on this website
                are not comprehensive descriptions of the final terms and
                conditions or precise cover provided by those products and
                services. The relevant terms and conditions for each individual
                product you wish to purchase will be provided either physically
                or electronically. The particular terms and conditions of any
                product on offer shall prevail over contradictions with the
                'Terms and Use' agreement of this website in regards to the
                policies and insurance of that product.
              </p>
              <p>
                Before cover can be processed or confirmed it is necessary for
                you to fully and faithfully inform us of everything you know, or
                could reasonably be expected to know that will be relevant while
                requesting an insurance policy.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Online Transactions</h5>
              <p>
                This website allows you to make online purchases. No online
                transaction is final until you are sent an electronic
                confirmation of your process. In instances where electronic
                documents cannot be delivered for technological or legal
                constraints, a physical document will be posted to you. A
                communication of your acceptance of an offer and your
                confirmation of payment is required to finalize the process
                before you are notified of the confirmation.
              </p>
              <p>
                We are not responsible for loss of communication as a result of
                mechanical, software, computer, telecommunications or electronic
                failures. Should your confirmation of payment not reach you as a
                result of these failures Policy Xpert is not liable to you in
                any way for loss or damage caused. It is your responsibility to
                inform us if you cancel or change your email address and to
                ensure it remains capable of receiving new emails and
                notifications. Policy Xpert will not be liable if the email
                address you have provided is inactive or is part of an expired
                account.
              </p>
              <p>
                We may retain the credit card details you provide only for as
                long as it is necessary to facilitate transactions. We hold the
                right to refuse to process transactions at any time and for any
                reason to the extent permitted by law, and will not be held
                liable for damage or loss caused as a result.
              </p>
            </div>
            <div className="space_me">
              <h5 className="bold">Purchase and Payment</h5>
              <p>
                When you request for a quote on this website it will be provided
                to you electronically at no financial cost. If you choose to
                accept the quote and the relevant terms and conditions, and
                complete every required field in regards to your details, you
                are permitted to request your desired product. Based on the
                information provided by you, your request will then be accepted
                or rejected. Should the application be accepted and successful,
                a binding insurance agreement will be created for you.
              </p>
              <p>
                The due date for all purchases must be met before transactions
                can be confirmed. Only credit cards accepted by the website will
                be permitted for use in online transactions and the following
                details will be required: Credit card type, full name on credit
                card, credit card number & expiry date.
              </p>
              <p>
                It is your responsibility to ensure all details and information
                provided to this website in relation to payment are accurate and
                contain the sufficient funds to clear the cost of the purchased
                product or service. A binding insurance will only be presented
                after a successful charge against the credit card used in the
                transaction and the reception of payment by Policy Xpert.
              </p>
              <p>
                By using this website you acknowledgment and accept these 'Terms
                of Use' and the 'Privacy Statement'.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <MoreInformation />
          </div>
        </div>

        {/* <div className="row space_me">
            <div className="col-lg-9 col-md-9 col-sm-12">
              <h5 className="bold">Contact Information</h5>
              <p>
                If you have any questions or comments about these our Terms of
                Service as outlined above, you can contact us at:
              </p>
              <ul>
                <li>Policy Expert Limited</li>
                <li>9 West, Parklands</li>
              </ul>
              <p />
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
