import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { verifyLoad } from "./utils/helpers";

//Componets
import Footer from "./components/layout/Footer";

//Pages
import WriteReview from "./pages/WriteReview";
import WebsiteSearchResults from "./pages/WebsiteSearchResults";
import Partners from "./pages/Partners";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CookiePolicy from "./pages/CookiePolicy";
import LearnMore from "./pages/LearnMore";
import Complaints from "./pages/Complaints";
import PartnerWithUs from "./pages/PartnerWithUs";
import SiteMap from "./pages/Sitemap";

const Home = lazy(() => verifyLoad(import("./pages/Home")));
const Home_TZ = lazy(() => verifyLoad(import("./pages/Tanzania/Home")));
const Home_UG = lazy(() => verifyLoad(import("./pages/Uganda/Home")));

const InsuranceQuotes = lazy(() => import("./pages/InsuranceQuotes"));

const ContactUs = lazy(() => import("./pages/ContactUs"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Learn = lazy(() => import("./pages/Learn"));
const Quote = lazy(() => import("./pages/Quote"));
const ExploreInsurance = lazy(() => import("./pages/ExploreInsurance"));

const Comparison = lazy(() => import("./pages/Comparison"));

const NotFound404 = lazy(() => import("./pages/NotFound404"));

const Faqs = lazy(() => import("./pages/Faqs"));

const Covid = lazy(() => import("./pages/covid-guide/Covid"));
const CovidArticles = lazy(() => import("./pages/covid-guide/CovidArticles"));

const CountryGuide = lazy(() => import("./pages/country-guide/CountryGuide"));
const CountryGuideArticle = lazy(() =>
  import("./pages/country-guide/CountryGuideArticle")
);

const PolicyGuide = lazy(() => import("./pages/policy-guide/PolicyGuide"));
const PolicyGuideArticle = lazy(() =>
  import("./pages/policy-guide/PolicyGuideArticle")
);

const AppRoutes = ({ load }) => {
  //
  let data = {
    age: 45,
    name: "Joe Smith",
    phone: "+254709444444",
    email: "smitha@email.com",
    nationality: "JP",
    area_of_coverage: "KE",
    dependents: [
      {
        age: 43,
        relation: "spouse",
      },
      {
        age: 12,
        relation: "son",
      },
      {
        age: 16,
        relation: "daughter",
      },
    ],
    time_spent: "15 min",
    plans_viewed: ["Major Medical", "Foundation", "Lifestyle"],
    pages_viewed: ["/", "/quotes", "/country-guide", "/contact-us"],
    plan_comparisons: [
      [
        "Bupa Explorer Gold Plus",
        "Utra Plan",
        "Care Plus + Active Plus + Bloom + Smile",
      ],
      ["Apex", "Lifestyle"],
      ["Apex", "Lifestyle", "Care Plus + Active Plus + Bloom + Smile"],
    ],
    form_interactions: [
      "Changed age from 18 to 40",
      "Added spouse 35 years",
      "Changed spouse age from 35 to 43",
      "Altered benefits: Inpatient, Outpatient, Dental",
      "Altered benefits: Inpatient, Outpatient, Dental, Maternity",
      "Removed spouse",
      "Added son 12",
      "Added daughter 12",
      "Changed age from 40 to 45",
    ],
  };

  return (
    <>
      {load ? (
        <>
          <Switch>
            <Route path="/" exact component={Home} />

            <Route path="/tz" exact component={Home_TZ} />
            <Route path="/ug" exact component={Home_UG} />

            <Route path="/quote/:code/:name" exact component={Quote} />
            <Route path="/quotes" exact component={InsuranceQuotes} />
            <Route path="/compare" exact component={Comparison} />
            <Route path="/file-complain" exact component={Complaints} />

            <Route
              path="/learn-more/:section"
              exact
              strict
              component={LearnMore}
            />

            <Route path="/learn" exact component={Learn} />

            <Route
              path="/country-guide/:person_type?"
              exact
              component={CountryGuide}
            />

            <Route
              path="/country/:article_id/:article_title"
              exact
              component={CountryGuideArticle}
            />

            <Route path="/policy-guide" exact component={PolicyGuide} />

            <Route
              path="/policy/:article_id/:article_title"
              exact
              component={PolicyGuideArticle}
            />

            <Route path="/covid" exact component={Covid} />

            <Route
              path="/covid/:article_id/:article_title"
              exact
              component={CovidArticles}
            />

            <Route path="/search/:q" exact component={WebsiteSearchResults} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/cookie-policy" exact component={CookiePolicy} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsAndConditions}
            />

            <Route
              path="/explore-insurance/:section?"
              exact
              component={ExploreInsurance}
            />

            {/* <Route path="/plan/:name" exact component={PlanYourTripArticle} />
            <Route exact path="/plan">
              <Redirect to="/plan-your-trip" />
            </Route> */}

            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/review" exact component={WriteReview} />

            <Route path="/partners" exact component={Partners} />

            <Route path="/become-a-partner" exact component={PartnerWithUs} />

            <Route path="/faqs" exact component={Faqs} />

            <Route path="/sitemap" exact component={SiteMap} />

            <Route component={NotFound404} />
          </Switch>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppRoutes;
