import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { GlobalProvider } from "./context/GlobalState";
import AppRoutes from "./AppRoutes";
import { baseURL } from "./utils/helpers";
import {
  sitePassword,
  decryptData,
  encryptData,
  testJSON,
} from "./utils/helpers";
import { v4 as uuidv4 } from "uuid";
import ScrollToTop from "./ScrollToTop";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
// import jwt from "jwt-simple";
// import UnderMaintenance from "./components/UnderMaintenance";

if (!localStorage.hasOwnProperty("activity")) {
  localStorage.activity = JSON.stringify([]);
}

if (!localStorage.hasOwnProperty("user")) {
  localStorage.user = JSON.stringify({});
}

if (!localStorage.hasOwnProperty("_form") || !testJSON(localStorage._form)) {
  localStorage._form = JSON.stringify({
    country_code: null,
    nationality: null,
    frq: "MONTHLY",
    age: 18,
    coverage: 1,
  });
}

if (
  !localStorage.hasOwnProperty("_benefits") ||
  !testJSON(localStorage._benefits)
) {
  localStorage._benefits = JSON.stringify({
    inpatient: true,
    outpatient: false,
    maternity: false,
    dental: false,
  });
}

if (
  !localStorage.hasOwnProperty("_dependants") ||
  !testJSON(localStorage._dependants)
) {
  localStorage._dependants = JSON.stringify([]);
}

if (
  !localStorage.hasOwnProperty("_comparisons") ||
  !testJSON(localStorage._comparisons)
) {
  localStorage._comparisons = JSON.stringify([]);
}

window.country = "KE";

const App = () => {
  // const [authorization, setAuthorization] = useState(false);
  let router = useLocation();

  let timeout = null;

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      // console.log("time is up");
      // Insert your code to reset you app here
    }, 1000 * 60); // 60 Seconds
  };

  // // const router = useLocation();

  // useEffect(() => {
  //   console.log(window.location.pathname);
  //   // code here will run everytime the page changes
  // }, [window.location.pathname]);

  const [load, setload] = useState(undefined);

  let uuid = uuidv4();

  const user = {
    random_id: uuid,
    email_address: null,
    country_code: "KE",
    location: null,
  };

  // const timer = getCookie("timer");

  async function siteActions() {
    const IPGeolocationAPI = require("ip-geolocation-api-javascript-sdk");

    const ipgeolocationApi = new IPGeolocationAPI(
      "fd481b9e3f0d42459104e80c01336a50",
      false
    );

    async function registerClient(country, location) {
      localStorage.user = JSON.stringify(user);

      let inputs = JSON.parse(localStorage.user);

      inputs["email_address"] = inputs.random_id;
      inputs["country_code"] = window.country = country;
      inputs["location"] = location;

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(`${baseURL}/user/create`, inputs, config);

        localStorage.puid = encryptData(res.data.token);

        localStorage._form = JSON.stringify({
          nationality: country,
          country_code: country,
          frq: "MONTHLY",
          age: 18,
          coverage: 1,
          excess: "0-0",
        });

        if (res.data.email === inputs.email_address) {
          localStorage.user = JSON.stringify(inputs);

          setload(true);

          // setTimeout(() => {
          //   setload(true);
          // }, 100);
        }
      } catch (error) {}
    }

    const handleResponse = (user_location) => {
      if (typeof user_location !== "undefined") {
        const country = user_location.country_code2;
        const location =
          user_location.district +
          ", " +
          user_location.country_capital +
          "-" +
          user_location.country_name;

        registerClient(country, location);
      }
    };

    ipgeolocationApi.getGeolocation(handleResponse);

    // setInterval(() => {
    //   siteActions();
    //   console.log("trying");
    // }, 500);

    // const json_valid = testJSON(localStorage.user);

    // if (json_valid) {

    // } else {
    //   localStorage.user = JSON.stringify(user);
    //   siteActions();
    // }
  }

  const checkPassword = async () => {
    const { value: password } = await Swal.fire({
      title: "Enter site password",
      input: "password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        maxlength: 20,
        autocapitalize: "off",
        autocorrect: "off",
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (password === sitePassword) {
      localStorage.authorized = encryptData(sitePassword);

      // setAuthorization(true);

      Swal.close();
    } else {
      // setAuthorization(false);
      Swal.fire({
        title: "Wrong Password!",
        icon: "error",
        confirmButtonText: "Retry?",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        checkPassword();
      });
    }
  };

  // const whitelist = [
  //   "/covid",
  //   "/plan-your-trip",
  //   "/about-us",
  //   "/cookie-policy",
  //   "/privacy-policy",
  // ];

  const blacklist = ["/quotes", "/compare"];

  const _onMouseMove = () => {
    restartAutoReset();
  };

  // function tokenInfo() {
  //   if (localStorage.puid) {
  //     // var secret = process.env.REACT_APP_SECRET;
  //     var secret = "kimiko";
  //     var token = decryptData(localStorage.puid);

  //     var decoded = jwt.decode(token, secret);

  //     console.log(new Date(decoded.exp * 1000));

  //     console.log(decoded);
  //     return decoded;
  //   }
  //   return false;
  // }

  useEffect(() => {
    let preventReset = true;

    const url = window.location.pathname.split("/");

    if (url[1] !== "" && url[1].length < 3) {
      window.country = url[1];
    }

    if (blacklist.includes(router.pathname)) {
      // console.log("Nimejipata kwa list");
      preventReset = false;
    }

    // for (const path of whitelist) {
    //   console.log(path + " " + router.pathname);

    //   if (path === router.pathname) {
    //     preventReset = true;
    //   }
    // }

    // or

    // const preventReset = whitelist
    //   .map(path => path === router.pathname ? 1 : 0)
    //   .reduce((prev, curr) => prev + curr, 0)

    if (preventReset) {
      return;
    }

    restartAutoReset();

    window.addEventListener("mousemove", _onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", _onMouseMove);
      }
    };

    // eslint-disable-next-line
  }, [router.pathname]);

  useEffect(() => {
    if (
      !localStorage.hasOwnProperty("authorized") ||
      // eslint-disable-next-line eqeqeq
      decryptData(localStorage.authorized) != sitePassword
    ) {
      checkPassword();
    }

    if (!localStorage.hasOwnProperty("puid")) {
      siteActions();
    } else {
      // tokenInfo();
      setload(true);
    }

    // const register = _.isEmpty(JSON.parse(localStorage.user));
    // register ? siteActions() : setload(true);

    return () => {
      setload(undefined);
      // clearInterval(interval);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GlobalProvider>
        <Suspense fallback={<></>}>
          <ScrollToTop />
          <AppRoutes load={load} />
        </Suspense>
      </GlobalProvider>
    </>
    // <>
    //   {!authorization ? (
    //     <UnderMaintenance />
    //   ) : (
    //     <GlobalProvider>
    //       <Suspense fallback={<></>}>
    //         <ScrollToTop />
    //         <AppRoutes load={load} />
    //       </Suspense>
    //     </GlobalProvider>
    //   )}
    // </>
  );
};

export default App;
