const Appreducer = (state, action) => {
  // console.log("state ", state.calculated_reclaculated);
  // console.log("action", action.type);
  // console.log("payload", action.payload);

  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        previousInputs: action.payload,
      };
    case "SET_PREVIOUS_INPUTS":
      return {
        ...state,
        previousInputs: action.payload,
      };
    case "LOCK_UNLOCK_EXECUTION":
      return {
        ...state,
        lockExecution: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_TOP_PLANS":
      return {
        ...state,
        top_plans: action.payload,
      };
    case "SET_MORE_PLANS":
      return {
        ...state,
        more_plans: action.payload,
      };
    case "SET_UNDERWRITERS":
      return {
        ...state,
        underwriters: action.payload,
      };
    case "SET_MY_BLOGS":
      return {
        ...state,
        blogs: action.payload,
      };
    case "SET_COUNTRY":
      return {
        ...state,
        country: action.payload,
      };
    case "SET_EXECUTING":
      return {
        ...state,
        executing: action.payload,
      };
    case "SET_CALCULATED_RECALCULATED":
      return {
        ...state,
        calculated_reclaculated: action.payload,
      };
    case "SET_DEAL_STATUS":
      return {
        ...state,
        deal_closed: action.payload,
      };

    default:
      return state;
  }
};

export default Appreducer;
