import React from "react";
import { useForm } from "react-hook-form";

const MoreInformation = () => {
  // const { register, handleSubmit, reset } = useForm();

  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {};
  return (
    <div className="news_letter">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>
          Have any questions
          <br />
          Drop us an email
        </h5>
        {/* <br /> */}
        <input type="text" placeholder="Type your email address" />
        <input type="submit" value="SEND" />
      </form>
    </div>
  );
};

export default MoreInformation;
