import React, { useEffect } from "react";
import Swiper from "swiper";

import { Link } from "react-router-dom";
import { slider_ins_companies } from "../../utils/data";

const InsuranceList = () => {
  useEffect(() => {
    new Swiper(".insurance-list-swiper", {
      speed: 500,
      spaceBetween: 0,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 4,
        },
        640: {
          slidesPerView: 6,
        },
      },
      slidesPerView: 2,
    });

    return () => {
      // console.log("leaving after launching swiper");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="insurace-list">
      <div className="container" to="/partners">
        {/* <h2>Our partners</h2> */}
        <div className="py-0 d-flex flex-row justify-content-between align-items-center ">
          <div className="swiper-container insurance-list-swiper">
            <div className="swiper-wrapper">
              {slider_ins_companies.map((d, i) => (
                <div key={i} className="swiper-slide">
                  <Link to="partners" className="px-3" key={i}>
                    <img src={d.img} alt="" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceList;
