import React from "react";
import Navigation from "../components/layout/Navigation";

const PrivacyPolicy = () => {
  return (
    <div className="page">
      <Navigation />
      <div className="container my-5 text-dark">
        <div className="row space_me">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <h3 className="bold">Privacy Policy</h3>
            <hr className="my-4" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <p>
              This Privacy Policy has been written to explain to you, users of
              our websites and clients, what data we collect, how we use it, and
              our adherence to both local and international regulations such as
              the GDPR.
            </p>
            <p>
              The terms ‘we’, ‘our’, and ‘us’ used in this Privacy page refer to
              Pacific Prime Insurance Brokers Ltd; more commonly referred to as
              Pacific Prime. Find our company contact details using this link.
            </p>
            <p>
              Any unlawful or void provisions found within these 'Terms of Use'
              shall be deemed invalid without effect to the validity or
              enforceability of the remaining provisions. Please send your
              questions via email to info@policy-xpert.com if you have any
              issues with these terms and conditions.
            </p>
            <p>
              Insurance on this website is arranged through licensed insurers.
              Policy Xpert Limited ("PXL") is a registered member of the{" "}
              <b>[ insert insurance body]</b>. Policy Xpert is wholly operated
              and owned by <b>[ insert ownership]</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
