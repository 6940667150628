import React from "react";
import { Redirect, useParams } from "react-router-dom";
import Navigation from "../components/layout/Navigation";

function LearnMore() {
  const section = useParams();

  if (!section) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <Navigation />
      <div className="learn-sections">
        <div className="container py-3 my-3">
          <section>Travel</section>
          <section>Travel</section>
          <section>Travel</section>
          <section>Travel</section>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
