import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import menu from "../../img/icons/menu.svg";
import { X } from "react-feather";
// import SearchWebsite from "./SearchWebsite";
import Countdown from "../reuseables/Countdown";
// import ke from "../../img/flags/ke.png";
// import { GlobalContext } from "../../context/GlobalState";

const Navigation = (props) => {
  // const { country } = useContext(GlobalContext);

  const navItems = [
    {
      name: "Home",
      link: "/",
      active: true,
    },
    {
      name: "Covid-19 Guide",
      link: "/covid",
    },
    {
      name: "Country Guide",
      link: "/country-guide",
    },
    {
      name: "Policy Guide",
      link: "/policy-guide",
    },
    {
      name: "Explore Insurance",
      link: "/explore-insurance",
    },
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
  ];

  const [state, setstate] = useState({
    showMenu: false,
    links: [
      {
        name: "Home",
        link: "/",
        active: true,
      },
      {
        name: "Covid-19 Guide",
        link: "/covid",
      },
      {
        name: "About Us",
        link: "/about-us",
      },
      {
        name: "Contact Us",
        link: "/contact-us",
      },
      {
        name: "Teren Us",
        link: "/contact-us",
      },
    ],
    currentRoute: "/" + window.location.pathname.split("/")[1],
  });

  const { showMenu, currentRoute } = state;

  const userFlag = () => {
    const img_url =
      process.env.PUBLIC_URL +
      `/assets/flags/${window.country.toLowerCase()}.png`;

    return img_url;
  };

  const showDefault = (e) => {
    e.target.src = ``;
  };

  return (
    <>
      <Countdown />
      <div className="nav shadow-sm">
        <Countdown />
        <div className="border-bottom w-100">
          <div className="container d-flex flex-row justify-content-between nav-top">
            <div className="py-3">LOCAL EXPERTISE, GLOBAL COVERAGE</div>
            <div className="d-flex flex-row ">
              <div className="p-3 d-none d-md-flex ">
                CALL US ON :&nbsp;
                <Link href="tel: +97150 786 1621">+97150 786 1621</Link>
              </div>
              <div className="p-3 d-none d-md-flex ">
                EMAIL:&nbsp;
                <Link href="mailto: info@policy-xpert.com">
                  info@policy-xpert.com
                </Link>
              </div>
              {/* <div className="p-3 border-left border-right account-btn">
                MY ACCOUNT
              </div> */}
              <div className="p-3 border-left border-right flag-icon">
                <img onError={(e) => showDefault(e)} src={userFlag()} alt="" />
                &nbsp;{window.country.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
        <div className="border-top w-100 position-relative">
          <div className="container nv1 h-100  d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row flex-wrap">
                <Link to="/">
                  <img src={logo} className="main-logo my-3" alt="" />
                </Link>
              </div>
              <div className="ml-5 d-flex flex-row h-100 nav-links">
                {navItems.map((d, i) => (
                  <Link
                    key={i}
                    to={d.link}
                    className={
                      "nav-link px-3 d-flex flex-column justify-content-center  " +
                      (d.link === currentRoute && "active text-primary")
                    }
                  >
                    <span>{d.name}</span>
                  </Link>
                ))}
              </div>
            </div>
            <div
              className="d-md-none"
              onClick={() => {
                setstate({ showMenu: !showMenu });
              }}
            >
              {showMenu ? (
                <X className="text-dark" size={25}></X>
              ) : (
                <img src={menu} className="menu-icon" alt="" />
              )}
            </div>
            {/* <SearchWebsite /> */}
          </div>
        </div>
      </div>
      <div className="nav-padding"></div>
      {showMenu && (
        <>
          <div className="side-menu">
            <div class="list-group">
              {navItems.map((d, i) => (
                <Link
                  key={i}
                  to={d.link}
                  className={
                    "list-group-item list-group-item-action  " +
                    (d.link === currentRoute && "active text-primar")
                  }
                >
                  <span>{d.name}</span>
                </Link>
              ))}
            </div>
          </div>
          <div
            className="side-menu-bg"
            onClick={() => {
              setstate({ showMenu: !showMenu });
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default Navigation;
