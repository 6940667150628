import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import { useForm } from "react-hook-form";
import partner_bg from "../img/partner_bg.jpg";
import Navigation from "../components/layout/Navigation";
import InsuranceList from "../components/reuseables/InsuranceList";
import Swal from "sweetalert2";

const SiteMap = () => {
  const [loading, setloading] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    new Swiper(".swiper-for-testimonials", {
      speed: 500,
      spaceBetween: 0,
      autoplay: {
        delay: 4000,
      },
      loop: true,
      breakpoints: {
        990: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3,
        },
        550: {
          slidesPerView: 1,
        },
        360: {
          slidesPerView: 1,
        },
      },
      slidesPerView: 1,
    });

    return () => {
      // console.log("leaving after launching swiper");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="about text-dark">
      <Navigation />
      <div className="page-content">
        <header className="bg-primary position-relative d-flex flex-row justify-content-between align-items-center">
          <img src={partner_bg} alt="" className="banner" />
          <div className="container position-relative">
            <div className="text-white text-center">
              <h1 className="about-title">SiteMap</h1>
              {/* <h5 className="bold my-5">
                Policy Xpert is a health insurance comparison aggregator that
                helps customers compare prices on a range of products and
                services for free.
              </h5> */}
            </div>
          </div>
        </header>
        <div className="container">
          <div className="row partner-section">
            <div className="col-md-7 col-12">
              <h2 className="my-3">We partner with the best in the Industry</h2>
              <p>
                Policy Xpert is a health insurance comparison aggregator that
                helps customers compare prices on a range of products and
                services for free.
              </p>
              <p>
                We are extremely proud of our network of expatriate insurance
                providers globally. Together with our vast experience across
                Africa and on the ground sales and administration teams, we aim
                to build long lasting and memorable relationships with all our
                clients. We believe in offering the best service at the right
                price to ensure all medical treatments within a specific term of
                benefits are catered for across the African continent.
              </p>
              <p>
                If you are interested in becoming an insurance partner of Policy
                Xpert please don’t hesitate to get in touch below and a member
                of our team with reach out as soon as possible to assist you.
              </p>
            </div>
            <div className="col-md-5 col-12">
              <h4>Join our network today</h4>
              <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control my-3"
                  required={true}
                  ref={register({
                    required: "Please add your name",
                  })}
                  disabled={loading ? true : false}
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="form-control my-3"
                  required={true}
                  ref={register({
                    required: "Please provide email address",
                  })}
                  disabled={loading ? true : false}
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  className="form-control my-3"
                  required={true}
                  ref={register({
                    required: "Please provide phone number",
                  })}
                  disabled={loading ? true : false}
                />
                <button
                  type="submit"
                  className="btn btn-lg btn-secondary mr-3 text-white px-4 mt-4"
                  disabled={loading ? true : false}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div>
          <InsuranceList />
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
