import React, { createContext, useReducer, useEffect } from "react";
import { articles, default_countries } from "../utils/data";
import axios from "axios";
import { decryptData, baseURL } from "../utils/helpers";
import AppReducer from "./AppReducer";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const initialState = {
  previousInputs: [],
  blogs: [],
  country: null,
  top_plans: [],
  more_plans: [],
  underwriters: {},
  comparisons: [],
  executing: true,
  isLoading: null,
  lockExecution: false,
  quote: {},
  error: { status: false, messgae: "" },
  deal_closed: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  //
  const history = useHistory();

  const [state, dispatch] = useReducer(AppReducer, initialState);

  // function lockUnlockExecution(to_set) {
  //   dispatch({
  //     type: "LOCK_UNLOCK_EXECUTION",
  //     payload: to_set,
  //   });
  // }

  function loadingStatus(to_set) {
    dispatch({
      type: "SET_LOADING",
      payload: to_set,
    });
  }

  async function myFunction() {
    dispatch({
      type: "SET_MY_STATE",
    });
  }

  function overallBenefit() {
    let total;

    if (!localStorage.hasOwnProperty("_benefits")) {
      total = 1;
    } else {
      const benefits = JSON.parse(localStorage._benefits);

      let inpatient = 1;
      let outpatient = 0;
      let maternity = 0;
      let dental = 0;
      total = 0;

      if (benefits.outpatient) {
        outpatient = 1;
      }

      if (benefits.maternity) {
        maternity = 2;
      }

      if (benefits.dental) {
        dental = 1;
      }

      total = inpatient + outpatient + maternity + dental;
    }

    return total;
  }

  function overallFormDetails() {
    const form = JSON.parse(localStorage._form);

    return form;
  }

  const emptyPlans = async () => {
    dispatch({
      type: "SET_TOP_PLANS",
      payload: [],
    });

    dispatch({
      type: "SET_MORE_PLANS",
      payload: [],
    });
  };

  async function updateApplicantDetails() {
    dispatch({
      type: "SET_TOP_PLANS",
      payload: [],
    });

    dispatch({
      type: "SET_MORE_PLANS",
      payload: [],
    });

    let inputs = {};

    const form = JSON.parse(localStorage._form);

    const user = JSON.parse(localStorage.user);

    if (form.country_code === null || form.age === null) {
      loadingStatus(false);
    } else {
      //check previous state
      localStorage._comparisons = JSON.stringify([]);

      inputs.random_id = user.random_id;
      inputs.gender = 1;
      inputs.country_code = form.country_code;
      inputs.age = form.age;
      inputs.benefit = overallBenefit();
      inputs.excess = form.excess;

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: decryptData(localStorage.puid),
          },
        };

        const res = await axios.post(`${baseURL}/user/modify`, inputs, config);

        if (res.data.status === 200) {
          calculatePlans();
        }
      } catch (error) {}
    }
  }

  async function dependantAddUpdate(id, relation, age) {
    const form = overallFormDetails();

    let inputs = {};

    const user = JSON.parse(localStorage.user);

    inputs.random_id = user.random_id;
    inputs.dependent_id = id;
    inputs.gender = parseInt(relation) === 3 ? 1 : 2;
    inputs.age = parseInt(age);
    inputs.relation = parseInt(relation);
    inputs.country_code = form.country_code;
    inputs.benefit = overallBenefit();
    inputs.location = user.location;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: decryptData(localStorage.puid),
        },
      };

      const res = await axios.post(
        `${baseURL}/user/add_modify_dependant`,
        inputs,
        config
      );

      if (res) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function dependantRemove(id) {
    // console.log(id);
    let inputs = {};

    const form = JSON.parse(localStorage._form);

    const user = JSON.parse(localStorage.user);

    inputs.excess = form.excess;

    inputs.random_id = id;

    inputs.email_address = user.random_id;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: decryptData(localStorage.puid),
        },
      };

      const res = await axios.post(
        `${baseURL}/user/remove_dependant`,
        inputs,
        config
      );

      if (res) {
        return true;
      }

      // if (res.data) {
      //   getPlans();
      // }
    } catch (error) {}
  }

  async function setUpBlogs() {
    let user_articles;

    if (default_countries.includes(window.country)) {
      const filtered = articles.filter((article) =>
        article.code.includes(window.country)
      );

      user_articles = filtered[0].content;
    } else {
      const filtered = articles.filter((article) => article.code === "DEFAULT");

      user_articles = filtered[0].content;
    }

    dispatch({
      type: "SET_MY_BLOGS",
      payload: user_articles,
    });

    dispatch({
      type: "SET_COUNTRY",
      payload: window.country,
    });
  }

  // const sleep = (ms) => {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // };

  const closeDeal = () => {
    dispatch({
      type: "SET_DEAL_STATUS",
      payload: !state.deal_closed,
    });
  };

  const fetchQuote = async (code, name) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: decryptData(localStorage.puid),
        },
      };

      const res = await axios.get(
        `${baseURL}/quotes/plan?l_no=${code}&u_code=${name}`,
        config
      );

      if (res) {
        return true;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const calculatePlans = async () => {
    dispatch({
      type: "SET_TOP_PLANS",
      payload: [],
    });

    dispatch({
      type: "SET_MORE_PLANS",
      payload: [],
    });

    localStorage._comparisons = JSON.stringify([]);

    const form = overallFormDetails();
    const user = JSON.parse(localStorage.user);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: decryptData(localStorage.puid),
        },
      };
      const res = await axios.get(
        `${baseURL}/quotes/calculate?e_id=${user.random_id}&excess=${form.excess}`,
        config
      );

      if (!_.isEmpty(res)) {
        window.calculated_recalculated = true;

        getPlans();
      }
    } catch (error) {}
  };

  const getPlans = async () => {
    if (!localStorage.hasOwnProperty("puid")) {
      loadingStatus(false);
    } else {
      // emptyPlans();

      const form = overallFormDetails();
      const user = JSON.parse(localStorage.user);

      dispatch({
        type: "SET_PREVIOUS_INPUTS",
        payload: form,
      });

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: decryptData(localStorage.puid),
          },
        };

        const res = await axios.get(
          `${baseURL}/v.2/plans?e_id=${user.random_id}&frq=${
            form.frq
          }&ben=${overallBenefit()}&scp=${form.coverage}`,
          config
        );

        if (!_.isEmpty(res)) {
          dispatch({
            type: "SET_TOP_PLANS",
            payload: res.data.top,
          });

          dispatch({
            type: "SET_MORE_PLANS",
            payload: res.data.data,
          });

          dispatch({
            type: "SET_UNDERWRITERS",
            payload: res.data.underwriters,
          });

          setTimeout(() => {
            history.push("/quotes");

            Swal.close();

            loadingStatus(false);
          }, 100);
        }
      } catch (error) {
        loadingStatus(false);

        // console.log(error);

        // if (error) {
        //   history.push("/");
        // }
      }
    }
  };

  const registerActivity = async (action, description) => {
    // const user = JSON.parse(localStorage.user);
    // const inputs = {
    //   random_id: user.random_id,
    //   action,
    //   description,
    // };
    // try {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: decryptData(localStorage.puid),
    //     },
    //   };
    //   const res = await axios.post(
    //     `${baseURL}/activity/register`,
    //     inputs,
    //     config
    //   );
    //   console.log(res);
    // } catch (error) {}
  };

  // const asyncFoo = async () => {
  //   console.log("Try 1");
  //   await sleep(800);
  //   console.log("Try 2");
  //   await sleep(800);
  //   console.log("Try 3");
  //   await sleep(800);
  //   console.log("Try 4");
  // };

  // const checkCalculatedRecalculated = () => {
  //   const path = window.location.pathname.split("/")[1];

  //   const blacklist = ["quotes", "compare", "quote"];

  //   const is_present = blacklist.includes(path);

  //   if (is_present) {
  //     Swal.fire({
  //       icon: "warning",
  //       html: "Quote has expired",
  //       confirmButtonText: "Get new quote",
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //     });
  //   }

  //   console.log(is_present);
  // };

  useEffect(() => {
    setUpBlogs();

    // setTimeout(() => {

    // }, 800);

    // setInterval(() => {
    //   checkCalculatedRecalculated();
    // }, 1000);

    return () => {
      // clearInterval(interval);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        myFunction,
        setUpBlogs,
        getPlans,
        dependantRemove,
        dependantAddUpdate,
        updateApplicantDetails,
        loadingStatus,
        fetchQuote,
        calculatePlans,
        emptyPlans,
        registerActivity,
        closeDeal,

        quote: state.quote,
        blogs: state.blogs,
        country: state.country,
        top_plans: state.top_plans,
        more_plans: state.more_plans,
        underwriters: state.underwriters,
        executing: state.executing,
        isLoading: state.isLoading,
        lockExecution: state.lockExecution,
        deal_closed: state.deal_closed,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
